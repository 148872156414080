import React, {useEffect, useState} from "react"

import FormItem from "../../components/form/FormItem";
import {createFieldsObject, getDomain, getItems} from "../../functions/functions";
import SearchContragent from "../../components/form/SearchContragent";

export default function ClientShowFilter(props) {
    const fields = ['status', 'responsible', 'contragent', 'municipality', 'microdistrict', 'meeting_date_in', 'meeting_date_out']
    const [items, setItems] = useState([])

    useEffect(() => {
        if (props.options)
            setData(props.options)
    }, [props.options])

    const setData = (data) => {
        const meeting_date_in = {
            label: "Дата показа с",
            name: "meeting_date_in",
            placeholder: "",
            read_only: false,
            required: true,
            type: "date",
            value: ""
        }

        const meeting_date_out = {
            label: "Дата показа по",
            name: "meeting_date_out",
            placeholder: "",
            read_only: false,
            required: true,
            type: "date",
            value: ""
        }

        getItems(`${getDomain()}/api/client_show/`, 'OPTIONS', (data) => {
            const newOptions = {
                ...data.actions.POST,
                landplot: data.actions.POST.client_task.children.landplot,
                contragent: {...data.actions.POST.client_task.children.contragent, label: 'Контрагент'},
                executor: data.actions.POST.client_task.children.executor,
                municipality: {
                    ...data.actions.POST.client_task.children.landplot.children.municipality,
                    read_only: false,
                    type: "field"
                },
                microdistrict: {
                    ...data.actions.POST.client_task.children.landplot.children.microdistrict,
                    read_only: false,
                    type: "field",
                    label: 'Микрорайон',
                },
                meeting_date_in: meeting_date_in,
                meeting_date_out: meeting_date_out,
            }
            const new_fields = createFieldsObject(newOptions)
            setItems(new_fields)
        })
    }

    return (
        <>
            {Object.keys(items).length > 0 ?
                <>
                    {fields.map((key) => {
                        const value = props.values.find(item => item[key] !== undefined)
                        items
                        const new_field = items[key]
                        if (items[key].name === 'contragent') {
                            return <SearchContragent key={key} field={new_field} required={false} value={value}
                                                     canAdd={false}/>
                        } else {
                            return <FormItem key={key} field={new_field} required={false}
                                             value={value ? value[key] : ''}/>
                        }
                    })}
                    {/* <FormItem field={for_date} required={false} value={for_date_val ? for_date_val['for_date'] : ''} /> */}
                </> : <>загрузка</>}
        </>
    )
}
