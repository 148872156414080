import {getDomain} from "../../../functions/functions";

export default async function patchCalendarBooking(id, data) {
    const url = getDomain() + `/api/calendar/booking/${id}/`
    const response = await fetch(url, {
        method: 'PATCH',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'application/json'
        },
        credentials: "same-origin",
        body: JSON.stringify(data)
    })
    return {ok: !!response.ok, error: (await response.json())?.error}
}