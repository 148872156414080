import React from 'react'
import {checkNotifications} from '../../functions/functions';
import {Link} from "react-router-dom";
import SortingCell from "../../components/SortingCell";
import {getFormatedDate} from "../../functions/functionsNew";

const ClientTasksTable = (props) => {
    const page_notifications = props.notifications.filter(n => n.model_name.toLowerCase() === props.model_name.toLowerCase())

    const getDateType = (dateStr) => {
        const [day, month, year] = dateStr.split('.');
        return new Date(year, month - 1, day)
    }

    const compDates = (date1, date2) => {
        if (!date1 && !date2)
            return 0

        if (!date1)
            return -1
        if (!date2)
            return 1


        const a = getDateType(date1)
        const b = getDateType(date2)

        const oneDayInMs = 24 * 60 * 60 * 1000;

        const differenceInDays = (a - b) / oneDayInMs

        if (differenceInDays > 2) {
            return 1
        } else if (differenceInDays >= 0) {
            return 0
        } else {
            return -1
        }
    }
    return (
        <>
            {props.tableLoading ? props.tableLoading :
                <table className="table _client-show">
                    <thead>
                    <tr>
                        <SortingCell classes="_center" title={'№'} name={`id`}
                                     page_name={props.name}/>
                        <SortingCell classes="_center" title={'Микрорайон'}
                                     name={`client_task__landplot__micro_district`} page_name={props.name}/>
                        <SortingCell classes="_center" title={'Муниципальное образование'}
                                     name={`client_task__landplot__micro_district__municipality`}
                                     page_name={props.name}/>
                        <SortingCell classes="_left" title={'Кадастровый номер'} name={`client_task__landplot__cadastral`}
                                     page_name={props.name}/>
                        <SortingCell classes="_left" title={'Контрагент'} name={`client_task__contragent__fio`}
                                     page_name={props.name}/>
                        <th className="_center">Телефон контрагента</th>
                        <SortingCell classes="_center" title={'Взято в работу'} name={`created_at`}
                                     page_name={props.name}/>
                        <SortingCell classes="_center" title={'Назначено'} name={`start_date`} page_name={props.name}/>
                        <SortingCell classes="_center" title={'Дата исполнения'} name={`execution_date`}
                                     page_name={props.name}/>
                        <th className="_center">Дата показа</th>
                        <th className="_center">Плановая дата исполнения</th>
                        <SortingCell classes="_center" title={'Ответственный'} name={`responsible`}
                                     page_name={props.name}/>
                        <SortingCell classes="_center" title={'Статус'} name={`status`} page_name={props.name}/>
                    </tr>
                    </thead>
                    <tbody>
                    {props.items.map((item, idx) => {
                        const compRes = compDates(item.planned_execution_date, item.execution_date)
                        const notify = checkNotifications(item.id, page_notifications)
                        const status = () => {
                            if (!item.date_of_succeed && item.dead_line) {
                                const str = item.dead_line.split('.')
                                const dead_line = new Date(str[2], str[1] - 1, str[0])
                                const date_now = new Date()
                                const date = new Date();
                                date_now.setHours(0)
                                date_now.setMinutes(0)
                                date_now.setSeconds(0)
                                date_now.setMilliseconds(0)
                                date.setDate(date.getDate() + 2)
                                if (date_now > dead_line) {
                                    return 'просрочено'
                                } else if (date > dead_line) {
                                    return 'внимание'
                                }
                            }
                            return false
                        }

                        return (
                            <tr key={idx} className={notify ? '_notify-row' : ''}>
                                <td><Link to={`detail/${item.id}`} className="table__link">{item.id}</Link></td>
                                <td className="_left"><Link to={`detail/${item.id}`}
                                                            className="table__link">{item.micro_district}</Link>
                                </td>
                                <td className="_center">{item.municipality}</td>
                                <td className="_center">{item.cadastral_number}</td>
                                <td className="_center">{item.contragent}</td>
                                <td className="_center">{item.contragent_phone}</td>
                                <td className="_center">{item.created_at}</td>
                                <td className="_center">{item.start_date}</td>
                                <td className="_center"
                                    style={compRes === 0 ? {
                                        backgroundColor: '#FFD400',
                                        color: '#FFFFFF'
                                    } : compRes === -1 ? {backgroundColor: '#ED0A34', color: '#FFFFFF'} : {}}>
                                    {item.execution_date}</td>
                                <td className="_center">{item.meeting_date}</td>
                                <td className="_center">{item.planned_execution_date}</td>
                                <td className="_center">{item.responsible_name}</td>
                                <td className="_center"
                                    style={(() => {
                                        switch (item.status) {
                                            case 'Отклонено':
                                                return {backgroundColor: '#ED0A34', color: '#FFFFFF'}
                                            case 'Выполнено':
                                                return {backgroundColor: '#0DC268', color: '#FFFFFF'}
                                            case 'В работе':
                                                return {backgroundColor: '#FFD400', color: '#FFFFFF'}
                                            case 'В обработке':
                                            default:
                                                return {}
                                        }
                                    })()}>{item.status}</td>
                            </tr>
                        )
                    })}
                    </tbody>

                </table>
            }
        </>
    )
}

export default ClientTasksTable