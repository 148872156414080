import React from 'react';
import ClientShowModuleWrapper from "./ClientShowModuleWrapper";
import {InfoItem} from "/src/components/InfoItem";

export default function ClientShowInWorkStatus({responsible_field, meeting_date_field, meeting_place_field,}) {
    return (
        <ClientShowModuleWrapper status={'В работе'}>
            <InfoItem item={responsible_field} classes={'client-show-detail-info-item-disabled'}/>
            <InfoItem item={meeting_place_field} classes={'client-show-detail-info-item-disabled'}/>
            <InfoItem item={meeting_date_field} classes={'client-show-detail-info-item-disabled'}/>
        </ClientShowModuleWrapper>
    )
}