export default async function sendClientShowRequest(url, data, method, setResponse, setResponseStatus) {
    const response = await fetch(url, {
        method: method ? method : 'PATCH',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'application/json'
        },
        credentials: "same-origin",
        body: JSON.stringify(data)
    })

    if (setResponseStatus) {
        setResponseStatus(response.status)
    }

    if (setResponse) {
        setResponse(await response.json())
    }

    return !!response?.ok
}