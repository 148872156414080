import clientShowDetailAddResponsibleIcon from "../../../../public/page_icons/clientShowDetailAddResponsibleIcon.svg";
import React, {useState} from "react";
import FormItem from "../../../components/form/FormItem";
import FormErrorsContext from "../../../functions/formErrorsContext";
import {sendClientShowRequest} from "../api";
import {getDomain} from "../../../functions/functions";

export default function ClientShowDetailChooseResponsible({field, onSuccess, client_show_id, callError}) {
    const [formErrors, setFormErrors] = useState()
    return (
        <FormErrorsContext.Provider value={{formErrors, setFormErrors}}>
            <form className={'client-show-detail-add-responsible'} onSubmit={async (e) => {
                e.preventDefault()
                const formData = new FormData(e.currentTarget)
                const responsibleId = formData.get('responsible')
                field.value = Number(responsibleId)

                if (!responsibleId) {
                    callError && callError('Заполните поле ответственного')
                    return
                }

                const response = await sendClientShowRequest(`${getDomain()}/api/client_show/${client_show_id}/set_responsible/`, {
                    responsible: responsibleId,
                }, 'POST')

                if (response && onSuccess) {
                    onSuccess()
                }
            }}>
                <FormItem field={field} required/>
                <button type={'submit'} className={'btn _green'}>
                    <img src={clientShowDetailAddResponsibleIcon} alt={''}/> Назначить
                </button>
            </form>
        </FormErrorsContext.Provider>
    )
}