import jwt_decode from "jwt-decode";
import {revokeRefresh} from "../api/logout/logout";

const userGroups = () => localStorage.getItem("groups") ? JSON.parse(localStorage.getItem("groups")) : [] // Document_flow, Users, Admin

const userPermissions = () => localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [] // ['add_somemodel', 'view_somemodel', 'change_somemodel', 'delete_somemodel', other models...]

const userHasGroup = (group) => userGroups().indexOf(group) !== -1


const userHasPermission = (permission) => {

    const groups = localStorage.getItem("groups") ? JSON.parse(localStorage.getItem("groups")) : []
    const permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : []
    if (groups && permissions)
        return groups.indexOf('Admin') !== -1 || permissions.indexOf(permission.toLowerCase()) !== -1
    else if (groups) {
        return groups.indexOf('Admin') !== -1
    } else if (permissions) {
        return permissions.indexOf(permission.toLowerCase()) !== -1
    }
    return false
}

const dateValidate = function (data) {
    const day = +data.split('.')[0],
        month = --data.split('.')[1],
        year = +data.split('.')[2],
        date = new Date(year, month, day)

    if (date.getFullYear() == year && date.getMonth() == month && date.getDate() == day)
        return true
    else
        return false
}

const organisationCheck = (data) => {
    if (data.organisation.length === 1)
        return data.organisation[0].name
    else if (data.organisation.length === 2)
        return data.organisation[0].name + ', ' + data.organisation[1].name
    return ''
}

const postCheck = (data) => {
    if (data.post_bik && !data.post_fond)
        return data.post_bik
    else if (!data.post_bik && data.post_fond)
        return data.post_fond
    else if (data.post_bik && data.post_fond)
        return data.post_bik + ', ' + data.post_fond
    return ''
}

const mode = process.env.NODE_ENV
const getDomain = () => (apiConfig.baseUrlMedia) //'http://84.201.187.112:8012' http://192.168.32.50:8712 https://collback.bik31.ru  http://51.250.126.124:8010 'http://192.168.32.50:8001' '8011' 'https://collback.bik31.ru' 'http://192.168.32.50:8006'   

function setUserData(data, addUserData) {
    localStorage.setItem("user_id", data.id)
    addUserData(['user_id', data.id])

    // const newGroups = []
    // const permissions = []
    // for (const group of data.groups) {
    //     try {
    //         for (const permission of group.permissions) {
    //             permissions.push(permission.codename)
    //         }
    //     } catch (error) {
    //         console.log('permissions error', group, error)
    //     }
    //     newGroups.push(group.name)
    // }
    //
    // localStorage.setItem("groups", JSON.stringify(newGroups))
    // addUserData(['groups', JSON.stringify(newGroups)])
    //
    // localStorage.setItem("permissions", JSON.stringify(permissions))
    // addUserData(['permissions', JSON.stringify(permissions)])
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // Заменено на функцию, которая используется для обновления прав и групп
    setUserPermissions(data, addUserData)

    const post = postCheck(data)
    localStorage.setItem("post", post)
    addUserData(['post', post])

    const organisation = organisationCheck(data)
    localStorage.setItem("organisation", organisation)
    addUserData(['organisation', organisation])


    if (data.skud_fond) {
        localStorage.setItem("skud_fond", JSON.stringify(data.skud_fond))
        addUserData(['skud_fond', JSON.stringify(data.skud_fond)])
    }

    const user_name = data?.last_name ? data?.last_name + ' ' + data?.first_name[0] + '.' + data?.patronymic[0] + '.' : data?.username
    localStorage.setItem("user_name", user_name)
    addUserData(['user_name', user_name])
}

const getUserId = () => {
    return localStorage.getItem('user_id')
}

const checkNotifications = (id, notification_list) => {
    const notifies = notification_list.filter((item) => item.instance_id === id)
    if (notifies.length === 0)
        return false
    return true
}

const logOut = (e) => {
    if (e)
        e.preventDefault()
    revokeRefresh(localStorage.getItem('refresh')).then((resp) => {
        localStorage.removeItem("token")
        localStorage.removeItem("refresh")
        localStorage.removeItem("user_id")
        localStorage.removeItem("user_name")
        localStorage.removeItem("groups")
        localStorage.removeItem("permissions")
        localStorage.removeItem("skud_fond")
        localStorage.removeItem("organisation")
        sessionStorage.removeItem("websocketState")

        const urlParams = new URLSearchParams(window.location.search);
        const next = urlParams.get('next');

        if (resp.success) {
            if (next) {
                window.location.replace(`login/?next=${next}`);
            } else {
                window.location.replace(`/login`)
            }
        } else {
            window.location.replace(`/login`)
        }

    })
}

const getInputNumbersValue = function (input) {
    return input.value.replace(/\D/g, "")
}

const getFormattedPhoneInputValue = function (numbers) {
    let inputNumbersValue = numbers
    let formattedInputValue = ""
    if (["7", "8", "9"].indexOf(inputNumbersValue[0]) > -1) {
        // russian number
        if (inputNumbersValue[0] === "9")
            inputNumbersValue = "7" + inputNumbersValue

        let firstSymbols = (inputNumbersValue[0] === "8") ? "+7" : "+7"

        formattedInputValue = firstSymbols + " "

        if (inputNumbersValue.length > 1)
            formattedInputValue += +inputNumbersValue.substring(1, 4)

        if (inputNumbersValue.length >= 5)
            formattedInputValue += " " + inputNumbersValue.substring(4, 7)

        if (inputNumbersValue.length >= 8)
            formattedInputValue += " " + inputNumbersValue.substring(7, 9)

        if (inputNumbersValue.length >= 10)
            formattedInputValue += " " + inputNumbersValue.substring(9, 11)

        return formattedInputValue
    } else {
        //not russian number
        return "+" + inputNumbersValue.substring(0, 16)
    }
}

const onKeyDown = function (input, setValue) {
    if (event.keyCode === 8 && getInputNumbersValue(input).length === 1) {
        setValue('')
    }
}

const onEmailInput = function (e, setValue, setError) {
    const re = /\S+@\S+\.\S+/,
        value = e.target.value
    setValue(value)
    if (re.test(value) === false) {
        setError("Введите корректный email")
    } else {
        setError("")
    }
}

const getFormattedDateTimeInputValue = function (numbers, input, setError) {
    let inputNumbersValue = numbers
    let formattedInputValue = ""
    if (input.classList.contains('date-mask') || input.classList.contains('date-time-mask')) {
        if (inputNumbersValue.length > 0)
            formattedInputValue = inputNumbersValue.substring(0, 2)
        if (inputNumbersValue.length >= 3)
            formattedInputValue += "." + inputNumbersValue.substring(2, 4)
        if (inputNumbersValue.length >= 5)
            formattedInputValue += "." + inputNumbersValue.substring(4, 8)
        if (dateValidate(formattedInputValue) === false) {
            setError("Введите корректную дату")
        } else {
            setError("")
        }
    }
    if (input.classList.contains('date-time-mask')) {
        if (inputNumbersValue.length >= 9)
            formattedInputValue += " " + inputNumbersValue.substring(8, 10)
        if (inputNumbersValue.length >= 11)
            formattedInputValue += ":" + inputNumbersValue.substring(10, 12)
    }
    if (input.classList.contains('time-mask')) {
        if (inputNumbersValue.length > 0)
            formattedInputValue = " " + inputNumbersValue.substring(0, 2)
        if (inputNumbersValue.length >= 3)
            formattedInputValue += ":" + inputNumbersValue.substring(2, 4)
    }
    return formattedInputValue.toString()
}

const getFormattedSnilsValue = function (numbers, input) {
    let inputNumbersValue = numbers
    let formattedInputValue = ""
    if (inputNumbersValue.length > 0)
        formattedInputValue = inputNumbersValue.substring(0, 3)
    if (inputNumbersValue.length >= 4)
        formattedInputValue += "-" + inputNumbersValue.substring(3, 6)
    if (inputNumbersValue.length >= 7)
        formattedInputValue += "-" + inputNumbersValue.substring(6, 9)
    if (inputNumbersValue.length >= 10)
        formattedInputValue += " " + inputNumbersValue.substring(9, 11)
    return formattedInputValue.toString()
}

const getFormattedKadastrValue = function (numbers, input) {
    let inputNumbersValue = numbers
    let formattedInputValue = ""
    if (inputNumbersValue.length > 0)
        formattedInputValue = inputNumbersValue.substring(0, 2)
    if (inputNumbersValue.length >= 3)
        formattedInputValue += ":" + inputNumbersValue.substring(2, 4)
    if (inputNumbersValue.length >= 5)
        formattedInputValue += ":" + inputNumbersValue.substring(4, 11)
    if (inputNumbersValue.length >= 12)
        formattedInputValue += ":" + inputNumbersValue.substring(11, 15)
    return formattedInputValue.toString()
}

const getFormattedInnValue = function (numbers, input) {
    let inputNumbersValue = numbers
    let formattedInputValue = ""
    if (inputNumbersValue.length > 0)
        formattedInputValue = inputNumbersValue.substring(0, 12)
    return formattedInputValue.toString()
}

function onPhoneInput(e, setValue) {
    const input = e.target
    let inputNumbersValue = getInputNumbersValue(input);
    const selectionStart = input.selectionStart;

    if (!inputNumbersValue)
        return input.value = ""

    if (input.value.length !== selectionStart) {
        setValue(getFormattedPhoneInputValue(inputNumbersValue))
        input.selectionStart = selectionStart
        input.selectionEnd = selectionStart
    }

    input.addEventListener('keydown', () => onKeyDown(input, setValue))
    setValue(getFormattedPhoneInputValue(inputNumbersValue))
}

function onDateTimeInput(e, setValue, setError) {
    const input = e.target
    let inputNumbersValue = getInputNumbersValue(input);
    const selectionStart = input.selectionStart;

    if (!inputNumbersValue)
        return input.value = ""

    if (input.value.length !== selectionStart) {
        setValue(getFormattedDateTimeInputValue(inputNumbersValue, input, setError))
        input.selectionStart = selectionStart
        input.selectionEnd = selectionStart
    }

    input.addEventListener('keydown', () => onKeyDown(input, setValue))
    setValue(getFormattedDateTimeInputValue(inputNumbersValue, input, setError));
}

function onSnilsInput(e, setValue) {
    const input = e.target
    let inputNumbersValue = getInputNumbersValue(input);
    const selectionStart = input.selectionStart;

    if (!inputNumbersValue)
        return input.value = ""

    if (input.value.length !== selectionStart) {
        setValue(getFormattedSnilsValue(inputNumbersValue, input))
        input.selectionStart = selectionStart
        input.selectionEnd = selectionStart
    }

    input.addEventListener('keydown', () => onKeyDown(input, setValue))
    setValue(getFormattedSnilsValue(inputNumbersValue, input))
}

function onKadastrInput(e, setValue) {
    const input = e.target
    let inputNumbersValue = getInputNumbersValue(input);
    const selectionStart = input.selectionStart;

    if (!inputNumbersValue)
        return input.value = ""

    if (input.value.length !== selectionStart) {
        setValue(getFormattedKadastrValue(inputNumbersValue, input))
        input.selectionStart = selectionStart
        input.selectionEnd = selectionStart
    }

    input.addEventListener('keydown', () => onKeyDown(input, setValue))
    setValue(getFormattedKadastrValue(inputNumbersValue, input))
}

function onInnInput(e, setValue) {
    const input = e.target
    let inputNumbersValue = getInputNumbersValue(input);
    const selectionStart = input.selectionStart;

    if (!inputNumbersValue)
        return input.value = ""

    if (input.value.length !== selectionStart) {
        setValue(getFormattedInnValue(inputNumbersValue, input))
        input.selectionStart = selectionStart
        input.selectionEnd = selectionStart
    }

    input.addEventListener('keydown', () => onKeyDown(input, setValue))
    setValue(getFormattedInnValue(inputNumbersValue, input))
}

const getItems = (url, method, setData, body = false, setError = () => {
}) => {
    //console.log(' ------------- actual token', localStorage.getItem("token"))
    fetch(url, body ?
        typeof body === 'string' ?
            {
                method: method,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`,
                    'Content-Type': 'application/json'
                },
                body: body
            } :
            {
                method: method,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                },
                body: body
            }
        : {
            method: method,
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            // console.log('getItems', response)
            // throw Error('При загрузке произошла ошибка, попробуйте обновить страницу или зайти позже.')
            if (!response.ok) {
                if (response.status === 401) {
                    window.location.replace('/refresh/')
                } else {
                    throw Error(`При загрузке произошла ошибка, попробуйте обновить страницу или зайти позже (${response.status})`)
                }
            }
            if (response.status === 204) {
                throw Error('Ошибка сервера (нет контента). Попробуйте позже.')
            }
            return response.json()
        })
        .then(data => {
            setData(data)
        })
        .catch(error => {
            if (error.message.toLowerCase() === 'failed to fetch') {
                setError('Доступ запрещен')
            } else {
                setError(error.message)
            }
        })
}
const sendFile = function (e, addField, setLoading, setError) {
    const input = e.target,
        files = [...input.files]
        , new_value = []

    setLoading(true)
    setError('')
    for (let i = 0; i < files.length; i++) {
        const form = new FormData()
        form.append('file', files[i])

        fetch(getDomain() + '/api/incoming/upload/', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("token")}`
            },
            body: form,
        })
            .then(response => {

                if (!response.ok) {
                    throw Error('Ошибка загрузки файла')
                }
                return response.json()
            })
            .then(data => {
                const url = decodeURIComponent(data)
                new_value.push(url)
                if (new_value.length === files.length) {
                    addField(new_value)
                    setLoading(false)

                }
            })
            .catch(error => {
                console.error(error)
                setLoading(false)
                if (error.message === 'URI malformed') {
                    setError('Имя файла содержит запрещенные символы')
                } else if (error.message.toLowerCase() === 'failed to fetch') {
                    setError('Доступ запрещен')
                } else {
                    setError(error.message)
                }
            })
    }
}

const createOrUpdate = (e, method, setLoading, setAlert, setErrors, navigateTo, fields) => {
    e.preventDefault()
    setLoading(true)
    const form = e.currentTarget
        , url = form.action
        , formData = new FormData(form) // form.querySelectorAll(input)
        , formJson = {}
        ,
        nested_fields = ['saleaction', 'other_doc', 'act_files', 'other_files', 'doc_payment', 'form_files', 'level_agreements', 'archive_doc', 'pasport_doc', 'files', 'sub_dumping']

    //console.log('formJson', formJson)

    for (let [name, value] of formData) {
        if (name === 'password' && value === '') {
            formData.delete(name)
            break;
        }
        if (fields[name]) {
            if (nested_fields.includes(name)) {
                formJson[name] = JSON.parse(value)
            } else if (fields[name].type === 'nested' || fields[name].multiple === true) {
                if (formJson[name]) {
                    const arr = formJson[name]
                    arr.push(value)
                    formJson[name] = arr
                } else {
                    const arr = []
                    arr.push(value)
                    formJson[name] = arr
                }
            } else if (fields[name].type === 'boolean') {
                if (value === 'on') {
                    formJson[name] = true
                }
            } else if (fields[name].type === 'select') {
                if (value === '') {
                    formJson[name] = null //Возможен баг
                } else {
                    formJson[name] = value
                }
            } else {
                formJson[name] = value
            }

        }
    }

    for (const [key, value] of Object.entries(fields)) {
        if (!formJson[key] && value.type === "boolean") {
            formJson[key] = false
        }
    }

    console.log('---- Form Json ----', formJson)

    fetch(url, {
        method: method,
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'application/json'
        },
        credentials: "same-origin",
        body: JSON.stringify(formJson)
    })
        .then(response => {
            if (response.status === 200 || response.status === 201) {
                return [response.json(), 'success']
            } else if (response.status === 400) {
                return [response.json(), 'error']
            } else if (response.status === 401) {
                window.location.replace('/refresh/')
            } else {
                setTimeout(() => {
                    setLoading(false)
                    window.scrollTo({top: 0, behavior: 'smooth'})
                    setAlert({title: `Ошибка, статус: ${response.status}`, text: response.statusText, type: 'error'})
                }, 1000);
            }
            return response
        })
        .then(([data, status]) => {
            if (status === 'success') {
                setTimeout(() => {
                    //console.log(data)
                    data.then(d => navigateTo(d.id))
                    setLoading(false)
                    window.scrollTo({top: 0, behavior: 'smooth'})
                    setAlert({title: 'Данные сохранены', type: 'success'})
                }, 1000)
                // setTimeout(() => {
                //     navigate(-1)
                // }, 3000)
            } else if (status === 'error') {
                setTimeout(() => {
                    setLoading(false)
                    window.scrollTo({top: 0, behavior: 'smooth'})
                    setErrors(data)
                    setAlert({title: `Ошибка`, text: 'Неверно заполнены поля', type: 'error'})
                }, 1000);
            } else {
                console.log(data)
            }
        })
}

const createFieldsObject = (data) => {
    const obj = {}
    console.log('raw data', data)
    for (const key in data) {
        const newField = new Object()
        const field = {
            name: key,
            label: data[key].label,
            type: data[key].type,
            value: '',
            required: data[key].required,
            read_only: data[key].read_only,
            placeholder: '',
            error: ''
        }

        if (data[key].type === "field" || data[key].type === "string" || data[key].type === "integer")
            field.type = "text"
        if (data[key].type === "file upload")
            field.type = "file"
        if (data[key].type === "field" && data[key].choices) {
            if (key === "members") {
                if (data[key].choices) {
                    field.choices = data[key].choices
                    field.type = 'nested'
                    for (const c of field.choices) {
                        c.label = c.display_name
                    }
                }

            } else {
                field.type = "select"
                field.children = data[key].choices
                for (const c of field.children) {
                    c.label = c.display_name
                }
            }
        }
        if (key === "landplot") {
            // console.dir(data[key])

            const condition = data[key]?.children?.condition
            const land_type = data[key]?.children?.type

            if (condition) {
                condition.type = "select"
                condition.name = "condition"
                condition.value = ""
                condition.placeholder = ""
                condition.children = condition.choices
                if (condition.children?.length > 0) {
                    for (const c of condition.children) {
                        c.label = c.display_name
                    }
                }
                newField['condition'] = condition
            }
            if (land_type) {
                land_type.type = "select"
                land_type.name = "land_type"
                land_type.value = ""
                land_type.placeholder = ""
                land_type.children = land_type.choices
                if (land_type.children?.length > 0) {
                    for (const c of land_type.children) {
                        c.label = c.display_name
                    }
                }

                newField['land_type'] = land_type
            }
        }
        if (key === "level_agreements")
            field.choices = data[key].child.children.rel_users.choices
        else {
            if (data[key].type === "choice") {
                field.type = "radio"
                field.choices = data[key].choices
                for (const c of field.choices) {
                    c.label = c.display_name
                }
            }
            if (data[key].child) {
                if (data[key]?.child?.children?.file) {
                    data[key].child.children.file.value = field.value
                    field.type = 'nested_files'
                } else if (key === 'doc_payment') {
                    field.type = 'nested_files'
                } else {
                    if (data[key].child.children) {
                        field.choices = data[key].child.children[Object.keys(data[key].child.children)[0]].choices
                        if (field.choices) {
                            field.type = 'nested'
                            for (const c of field.choices) {
                                c.label = c.display_name
                            }
                        } else {
                            field.type = 'nested'
                        }
                    }
                }
            }
        }

        if (data[key].type === "integer")
            field.type = "number"
        if (data[key].type === "boolean")
            field.type = "boolean"
        if (data[key].type === "float")
            field.type = "text"

        if (key === 'description')
            field.type = 'textarea'
        if (key === 'resolution')
            field.type = 'textarea'
        if (data[key].multiple)
            field.multiple = true


        newField[key] = field
        Object.assign(obj, newField)
    }
    return obj
}

const getFileName = (name) => {
    // console.log('================================>>>>>>>!!!!!!!!!!!!!!!!!!!!!', name.replace(/%([^\d].)/, "%25$1"))
    try {
        const decodedUrl = decodeURIComponent(name)
            , arr_path = decodedUrl.split('/')
            , file_name = arr_path[arr_path.length - 1]
            , file_name_arr = file_name.split('_')
            , new_name = file_name.replace('_' + file_name_arr[file_name_arr.length - 1].split('.')[0], "")
        return new_name
    } catch (e) {
        console.error('Bad file name', name)
        const path = name.split('\/')
        return path[path.length - 1]
    }
}

import {useEffect, useState} from "react"
import apiConfig from "../api/apiConfig";
import setUserPermissions from "./setUserPermissions";

const getFormatedDate = (date) => {
    let formated = ''
    const get_date = new Date(date)
    const day = '00' + get_date.getDate()
    const month = '00' + (get_date.getMonth() + 1)
    const year = get_date.getFullYear()
    formated = `${day.substr(-2)}-${month.substr(-2)}-${year}`
    return formated
}
const getHtmlFormatDate = (dateString) => {
    let formated = '-';
    const [day, month, year] = dateString.split('-');
    const get_date = new Date(`${year}-${month}-${day}`);

    const formattedDay = '00' + get_date.getDate();
    const formattedMonth = '00' + (get_date.getMonth() + 1); // Add 1 to the month
    const formattedYear = get_date.getFullYear();

    formated = `${formattedYear}-${formattedMonth.substr(-2)}-${formattedDay.substr(-2)}`;
    return formated;
};

const getDateTime = date => {
    const get_date = new Date(date)
    const day = '00' + get_date.getDate()
    const month = '00' + (get_date.getMonth() + 1)
    const year = get_date.getFullYear()
    const hours = '00' + get_date.getHours()
    const minutes = '00' + get_date.getMinutes()
    const dateStr = `${day.substr(-2)}.${month.substr(-2)}.${year} ${hours.substring(2)}:${minutes.substring(2)}`
    return dateStr
}
const getDayMounthTime = date => {
    const get_date = new Date(date);

    // Extracting day, month, year, hours, and minutes
    const day = '00' + get_date.getDate();
    const month = '00' + (get_date.getMonth() + 1);
    const year = get_date.getFullYear();
    const hours = '00' + get_date.getHours();
    const minutes = '00' + get_date.getMinutes();

    // Creating the formatted date string
    const dateStr = `${day.substr(-2)}.${month.substr(-2)} ${hours.substring(2)}:${minutes.substring(minutes.length - 2)}`;

    return dateStr;
};


function fieldToArray(fields) {
    if (!fields) {
        return [];
    }

    const fieldEntries = Object.entries(fields);

    if (fieldEntries.length === 0) {
        return [];
    }

    const resultArray = fieldEntries.map(([key, value]) => ({key, value}));
    return resultArray;
}

export default function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
        return '0';
    } else {
        var k = 1024;
        var dm = decimals < 0 ? 0 : decimals;
        var sizes = ['байт', 'КБ', 'МБ', 'ГБ', 'ТБ'];
        var i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
}

const transformDate = (inputDate, split) => {
    const parts = inputDate.split(`${split}`);

    if (parts.length === 3) {
        const day = parts[0];
        const month = parts[1];
        const year = parts[2];

        return `${year}-${month}-${day}`;
    } else {
        return 'Invalid Date';
    }
}

// Получение значения куки по имени
function getCookie(name) {
    const cookieName = `${name}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');

    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(cookieName) === 0) {
            return cookie.substring(cookieName.length, cookie.length);
        }
    }
    return null;
}

//проверка на массив массивов
function hasNestedArray(arr) {
    for (let i = 0; i < arr.length; i++) {
        if (Array.isArray(arr[i])) {
            return true; // Если элемент массив, вернуть true
        }
    }
    return false; // Если ни один элемент не является массивом, вернуть false
}

function hasDoubleNestedArray(arr) {
    for (let i = 0; i < arr.length; i++) {
        if (Array.isArray(arr[i])) {
            for (let j = 0; j < arr[i].length; j++) {
                if (Array.isArray(arr[i][j])) {
                    return true; // Если найден двойной вложенный массив, вернуть true
                }
            }
        }
    }
    return false; // Если двойной вложенный массив не найден, вернуть false
}

function hasTripleNestedArray(arr) {
    for (let i = 0; i < arr.length; i++) {
        if (Array.isArray(arr[i])) {
            for (let j = 0; j < arr[i].length; j++) {
                if (Array.isArray(arr[i][j])) {
                    for (let k = 0; k < arr[i][j].length; k++) {
                        if (Array.isArray(arr[i][j][k])) {
                            return true; // Если найден тройной вложенный массив, вернуть true
                        }
                    }
                }
            }
        }
    }
    return false; // Если тройной вложенный массив не найден, вернуть false
}


const useScreenWidthMobile = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const thresholdWidth = 600;

    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    useEffect(() => {
        // Subscribe to the window resize event
        window.addEventListener('resize', handleResize);

        // Unsubscribe from the event when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array means this effect runs once, similar to componentDidMount

    // Return the result based on the screen width
    return screenWidth >= thresholdWidth;
};


export {
    logOut,
    getItems,
    sendFile,
    getDomain,
    setUserData,
    organisationCheck,
    postCheck,
    checkNotifications,
    onPhoneInput,
    onDateTimeInput,
    onSnilsInput,
    onKadastrInput,
    onInnInput,
    createFieldsObject,
    userGroups,
    userHasGroup,
    userHasPermission,
    getFileName,
    onEmailInput,
    createOrUpdate,
    dateValidate,
    getDayMounthTime
}