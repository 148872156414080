import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom"
import {useSelector} from "react-redux"


import {InfoItem} from "../../components/InfoItem";
import {createFieldsObject, getDomain, getItems} from "../../functions/functions";
import FormItem from "../../components/form/FormItem";
import CommentComponent from "../../components/CommentCmponent/CommentComponent"
import PersonalAccesses from "../../components/form/PersonalAccesses/PersonalAccesses";
import {
    ClientShowCancelStatus,
    ClientShowCompletedStatus,
    ClientShowInProgressStatus,
    ClientShowInWorkStatus
} from "./components";
import HistoryComponent from "../../components/HistoryComponent/HistoryComponent";


function ClientsTasksDetail(props) {


    const [fields, setFields] = useState({})
    const [landplotType, setLandplotType] = useState({})
    const date = new Date();
    const formattedDate = date.toLocaleDateString(); // Формат даты: "04.07.2023"
    const userData = useSelector(state => state.user_data.userData)
    const navigate = useNavigate()
    const [formErrors, setFormErrors] = useState([])
    const [loading, setLoading] = useState(false)
    const [valueEx, setValueEx] = useState(props?.executor?.value ? props.executor.value : '')

    const [clientShowOptions, setClientShowOptions] = useState()
    useEffect(() => {

        const url = `${getDomain()}/api/main/contragent/`

        getItems(`${getDomain()}/api/main/contragent/`, 'OPTIONS', setData)
        getItems(`${getDomain()}/api/client_task/${props.fields.id.value}/`, 'OPTIONS', (data) => {
            setLandplot(data)
            console.log('>>>>>>>>>>>>>>>>', data)
            setClientShowOptions(data.actions.PUT.client_for_show)
        })

    }, [])

    const setData = (data) => {
        const newFields = createFieldsObject(data.actions.POST)

        const setValues = (data) => {
            for (const key in data) {
                if (newFields[key]) {
                    newFields[key].value = data[key] ?? ''
                }
            }
            setFields(newFields)
        }
        getItems(`${getDomain()}/api/main/contragent/${props.fields.contragent.value.id}`, 'GET', setValues)
    }

    const setLandplot = (data) => {
        const landplot_type = data?.actions?.PUT?.landplot?.children?.type?.choices?.filter(t => t.value === props.fields.landplot.value.type)
        setLandplotType(landplot_type)
    }

    const navigateToView = (id) => {
        const url = `/${props.name}/`
        navigate(url, {
            state: {
                message: {
                    title: 'Сохранено',
                    text: 'Объект изменен',
                    status: 'success'
                }
            }
        })
    }
    const succeedSend = (id, status_id) => {
        fetch(`${getDomain()}/api/client_task/${id}/set_status/`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${userData.token}`,
                'Content-Type': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify({"status": status_id})

        })
            .then(response => {
                //console.log('response', response)
                navigateToView()
            })
    }


    const isResponsible = () => {
        return (+props.fields?.responsible?.value === +userData.user_id)
    }

    const isExecutor = () => {
        return (+props.fields?.executor?.value === +userData.user_id)
    }


    const isAdmin = () => {
        const groups = userData.groups ? userData.groups : []
        if (groups.indexOf('Admin') !== -1) {
            return true
        } else {
            return false
        }
    }

    const ClientShowModule = () => {
        if (clientShowOptions === undefined)
            return
        const status = props.fields.client_for_show.value.status
        const client_for_show = props.fields.client_for_show.value
        const client_for_show_options = clientShowOptions.children

        if (!client_for_show)
            return

        switch (status) {
            case 'В работе':
                return <ClientShowInWorkStatus
                    meeting_place_field={{
                        ...client_for_show_options.meeting_place,
                        value: client_for_show.meeting_place
                    }}
                    meeting_date_field={{
                        ...client_for_show_options.meeting_date,
                        value: client_for_show.meeting_date
                    }}
                    responsible_field={{
                        ...client_for_show_options.responsible_name,
                        value: client_for_show.responsible_name,
                    }}/>
            case 'Выполнено':
                return <ClientShowCompletedStatus
                    responsible_field={{
                        ...client_for_show_options.responsible_name,
                        value: client_for_show.responsible_name,
                    }}
                    files_field={{
                        ...client_for_show_options.files,
                        value: client_for_show.files,
                        label: undefined,
                        type: 'nested_files'
                    }}/>
            case 'Отклонено':
                return <ClientShowCancelStatus
                    responsible_field={{
                        ...client_for_show_options.responsible_name,
                        value: client_for_show.responsible_name,
                    }}
                    decline_reason_field={{
                        ...client_for_show_options.decline_reason,
                        value: client_for_show.decline_reason
                    }}/>
            case 'В обработке':
            default:
                return <ClientShowInProgressStatus/>
        }
    }

    return (
        <>
            <div className={"page__info-container"}>

                <InfoItem item={props.fields.create_at} classes='_full'/>

                {/* {props.fields.landplot?.value?.cadastral &&
                    <div className={`info__item _full`}>
                        <span className="info__label">Условия приобретения</span>
                        <div className="info__text" >{landplotType[0]?.display_name}</div>
                    </div>
                } */}


                {/* ----------------    Условия приобретения  ---------------- */}
                {props.fields.landplot?.value?.condition &&
                    <div className={`info__item _full`}>
                        <span className="info__label">Условия приобретения</span>
                        <div className="info__text">{props.fields.landplot.value.condition}</div>
                    </div>
                }

                {/* ----------------    Тип участка  ---------------- */}
                {props.fields.landplot?.value?.type &&
                    <div className={`info__item _full`}>
                        <span className="info__label">Тип участка</span>
                        <div className="info__text">{props.fields.landplot.value.type}</div>
                    </div>
                }

                {/* ----------------    Номер участка  ---------------- */}
                {props.fields.landplot?.value?.number &&
                    <div className={`info__item _full`}>
                        <span className="info__label">Номер участка</span>
                        <div className="info__text">{props.fields.landplot.value.number}</div>
                    </div>
                }


                {/* ----------------   Кадастр. номер участка   ---------------- */}
                {props.fields.landplot?.value?.cadastral &&
                    <div className={`info__item _full`}>
                        <span className="info__label">{props.fields.landplot.label}</span>
                        <div className="info__text">{props.fields.landplot.value.cadastral}</div>
                    </div>
                }
                {/* ----------------   Площадь земельного участка   ---------------- */}
                {props.fields.landplot?.value?.cadastral &&
                    <div className={`info__item _full`}>
                        <span className="info__label">Площадь земельного участка</span>
                        <div className="info__text">{props.fields.landplot.value.area}</div>
                    </div>
                }

                {/* ----------------   Микрорайон   ---------------- */}
                {props.fields.landplot?.value?.microdistrict &&
                    <div className={`info__item _full`}>
                        <span className="info__label">Микрорайон</span>
                        <div className="info__text">{props.fields.landplot.value.microdistrict}</div>
                    </div>
                }
                {/* ----------------   	Муниципальное образование   ---------------- */}
                {props.fields.landplot?.value?.microdistrict &&
                    <div className={`info__item _full`}>
                        <span className="info__label">	Муниципальное образование</span>
                        <div className="info__text">{props.fields.landplot.value.municipality}</div>
                    </div>
                }
                {/* ----------------   Стоимость участка   ---------------- */}
                {props.fields.landplot?.value?.land_cost &&
                    <div className={`info__item _full`}>
                        <span className="info__label">Стоимость участка (₽)</span>
                        <div className="info__text">{props.fields.landplot.value.land_cost} </div>
                    </div>
                }
                {/* ----------------   Первоначальный взнос   ---------------- */}
                {props.fields.landplot?.value?.down_payment &&
                    <div className={`info__item _full`}>
                        <span className="info__label">Первоначальный взнос (₽)</span>
                        <div className="info__text">{props.fields.landplot.value.down_payment}</div>
                    </div>
                }

                <p className="_full form__sub-title m-">Данные контрагента</p>

                {/* ----------------   данные контрагента   ---------------- */}
                {Object.keys(fields).length > 0 &&
                    <>
                        {(fields.status.value === "Физическое лицо") ?
                            <>
                                <InfoItem item={fields.fio} classes="_full"/>
                                <InfoItem item={fields.birth_date}/>
                                <InfoItem item={fields.inn}/>
                                <InfoItem item={fields.fakt_address} classes="_full"/>
                                <InfoItem item={fields.phone} classes="_full"/>
                                <InfoItem item={fields.email} classes="_full"/>
                                <InfoItem item={fields.passport} classes="_full"/>
                                <InfoItem item={fields.www_password} classes="_full"/>
                                <InfoItem item={fields.work_place} classes="_full"/>
                                <InfoItem item={fields.family} classes="_full"/>
                                <InfoItem item={fields.spouse_fio} classes="_full"/>
                                <InfoItem item={fields.client} classes="_full"/>


                            </>
                            :
                            <>
                                <InfoItem item={fields.fio} classes="_full"/>
                                <InfoItem item={fields.status} classes="_full"/>
                                <InfoItem item={fields.org_form} classes="_full"/>
                                <InfoItem item={fields.inn} classes="_full"/>
                                <InfoItem item={fields.ogrn} classes="_full"/>
                                <InfoItem item={fields.kpp} classes="_full"/>
                                <InfoItem item={fields.org_name} classes="_full"/>
                                <InfoItem item={fields.fakt_address} classes="_full"/>
                                <InfoItem item={fields.ur_address} classes="_full"/>
                                <InfoItem item={fields.email} classes="_full"/>
                                <InfoItem item={fields.dir_fio} classes="_full"/>
                                <InfoItem item={fields.dir_phone} classes="_full"/>
                                <InfoItem item={fields.phone} classes="_full"/>
                                <InfoItem item={fields.birth_date}/>
                                <InfoItem item={fields.payment_account} classes="_full"/>
                                <InfoItem item={fields.bik} classes="_full"/>
                                <InfoItem item={fields.bank} classes="_full"/>
                                <InfoItem item={fields.cor_account} classes="_full"/>

                            </>
                        }
                        {/* Особые условия (льготы, многодетные) value = [num, num, ...] */}
                        {props.fields.preferencial.value.length > 0 ?
                            <div className={`info__item _full`}>
                                <span className="info__label">{props.fields.preferencial.label}</span>
                                {props.fields.preferencial.value.map((item, idx) => {
                                    return <p key={idx}
                                              className="info__text mb-8">{props.fields.preferencial.children.filter((choice) => choice.value == item)[0].display_name}</p>
                                })}
                            </div>
                            :
                            ''
                        }
                        {/* <InfoItem item={props.fields.executor} classes="_full" /> */}

                    </>
                }
            </div>

            {+props.fields?.status?.value == 4 ?
                <PersonalAccesses url={props.url} fields={props.fields} page_name={props.name} absolut_save_btn={false}
                                  client_tasks={true}>
                    <div className="page__info-container mt-12">
                        <FormItem classes="_full" field={props.fields.executor}/>
                    </div>
                    <div className={'page__info-container mt-16'}>

                        <div className="agreed_button">
                            <input type="submit" id="save_button" className="d-none" value="Send"/>
                            <label htmlFor="save_button"
                                   className="service-records_button _agreed m-0">Назначить</label>
                        </div>
                        <div className="disagreed_button">
                            <span htmlFor="disagreed_button" onClick={() => succeedSend(props.fields?.id?.value, 2)}
                                  className="service-records_button _disagreed m-0">Отклонить</span>
                        </div>
                    </div>
                </PersonalAccesses>
                :
                <div className="page__info-container mt-12">
                    <InfoItem item={props.fields.executor} classes="_full"/>
                </div>
            }


            <ClientShowModule/>

            {/* 1-'В работе' / 2-'Отклонено' / 3-'Выполнено' / 4- 'В обработке' / 5- 'Назначен исполнитель' */}

            {isExecutor() ?
                <>
                    {props.fields?.status?.value === 5 &&

                        <div className="page__info-container mt-16 records-buttons">
                            <div className="agreed_button">
                                <span htmlFor="agreed_button" onClick={() => succeedSend(props.fields?.id?.value, 1)}
                                      className="service-records_button _agreed">Принять в работу</span>
                            </div>
                            <div className="disagreed_button">
                                <span htmlFor="disagreed_button" onClick={() => succeedSend(props.fields?.id?.value, 2)}
                                      className="service-records_button _disagreed">Отклонить</span>
                            </div>
                        </div>
                    }
                    {props.fields?.status?.value === 1 &&
                        <div className="page__info-container mt-16 records-buttons">
                            <div className="agreed_button">
                                <span htmlFor="agreed_button" onClick={() => {
                                    succeedSend(props.fields?.id?.value, 3);
                                }} className="service-records_button _agreed m-0">Выполнить</span>
                            </div>
                            <div className="disagreed_button">
                                <span htmlFor="disagreed_button" onClick={() => succeedSend(props.fields?.id?.value, 2)}
                                      className="service-records_button _disagreed m-0">Отклонить</span>
                            </div>
                        </div>
                    }
                </>
                :
                <></>
            }

            {+props.fields?.status?.value === 3 &&
                <div className={"page__info-container"}>
                    <div className='service-records _status-agreed _full justify-content-center mt-24'>Выполнено</div>
                </div>
            }
            {+props.fields?.status?.value === 2 &&
                <div className={"page__info-container"}>
                    <div className='service-records _status-disagreed _full justify-content-center mt-24'>Отклонено
                    </div>
                </div>
            }

            {props.fields.comments &&
                <div className="lenta">
                    <CommentComponent comments={props.fields.comments} object_id={props.fields.id.value}
                                      type={props.type} title={'Лента действий'}/>
                </div>
            }
            <HistoryComponent id={props.id} name={'client_task'} />

        </>

    )
}

export default ClientsTasksDetail;