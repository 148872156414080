import {getDomain} from "./functions";
import {setUserDataItem, userDataSlice} from "../store/userDataSlice";
import {store} from "../store/store";
import setUserPermissions from "./setUserPermissions";

export default async function updateUserData() {
    const userId = store.getState(userDataSlice)?.user_data?.userData?.user_id

    if (!userId)
        return

    const response = await fetch(getDomain() + '/api/user/' + userId + '/user_permissions/', {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'application/json'
        },
    })

    const addUserData = (name, value) => store.dispatch(setUserDataItem(name, value))

    if (response?.ok) {
        console.debug('======================> update user data !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')
        const newUserData = await response.json()
        setUserPermissions(newUserData, addUserData)
    }
}