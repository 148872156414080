import React, { useEffect, useState } from 'react'
import ViewPage from '../../components/ViewPage'
import ViewContainer from '../../components/ViewContainer/ViewContainer'
import EventCalendarTable from './EventCalendarTable'
import { getItems } from '../../functions/functions'
import { useSelector } from 'react-redux'
import AddCalendarBookingButton from "../CalendarBooking/components/AddCalendarBookingButton";

const CalendarView = (props) => {



  return (
      <ViewContainer {...props} addition_components={<AddCalendarBookingButton/>}>
      <EventCalendarTable {...props} />
    </ViewContainer>

  )
}

export default CalendarView