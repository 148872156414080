import React,{useState, useEffect} from "react"

import {getFileName, getDomain, getItems} from "../functions/functions";
import { routes } from "../pages";

function InfoItem(props){

    // console.log('!!!!!!!!', props);

    if(typeof props.item.value === "number" && props.item.children){
        return (
            <div className={`info__item ${props.classes ? props.classes : ''}`}>
                <span className="info__label">{props.item.label}</span>
                <p className="info__text">{props.item.children.filter((choice) => choice.value === props.item.value)[0]?.label}</p>
            </div>
        )
    }
    else if (props.item.name === 'contragent'){
        return <ContagentSurchItem field={props.item} classes={props.classes}/>
    }
    else if( props.item.type === "nested" && props.item.choices){
        return <NestedItem field={props.item} classes={props.classes}/>     
    }
    else if (props.item.type === "select" && props.item.value.name){
        return (
            <div className={`info__item ${props.classes ? props.classes : ''}`}>
                <span className="info__label">{props.item.label}</span>
                <p className="info__text">{props.item.value.name}</p>
            </div>
        )
    }
    else if(typeof props.item.value === "boolean"){
        return (
            <div className={`info__item ${props.classes ? props.classes : ''}`}>
                <span className="info__label">{props.item.label}</span>
                <p className="info__text">{props.item.value ? 'Да' : 'Нет'}</p>
            </div>
        )
    }
    else if (typeof props.item.value === "object" && props.item.name === "saleaction"){
        return (
            <div className={`info__item ${props.classes ? props.classes : ''}`}>

                <div className="sale-action_detail">
                    { props.item.value.length > 0 ?
                        props.item.value.map((item, idx) => {
                            return(
                                <div className="sale-action_detail_item" key={idx}>
                                    <p className="sale-action_title">Акция</p>
                                    <div>
                                        <span className="info__label m-0">Cкидка или акция</span>
                                        <span className="info__text">{item.sale_action_name}</span>
                                    </div>

                                    <div>
                                        <span className="info__label m-0">Условия получения</span>
                                        <span className="info__text">{item.condition_of_receipt ? item.condition_of_receipt : "—"}</span>
                                    </div>

                                </div>
                            )
                        })
                        : ''
                    }
                </div>
            </div>
        )
    }
    else if (typeof props.item.value === "object"){
        return (
            <div className={`info__item ${props.classes ? props.classes : ''}`}>
                <span className="info__label">{props.item.label}</span>
                <p className="info__text">

                    { props.item.value?.length > 0 ?
                        props.item.value.map((item, idx) => {
                            return(
                                <span key={idx}>{item.name}</span>
                            )
                        })
                        : props.value ? props.value : ''
                    }
                </p>
            </div>
        )
    }

    else if (props.item.value === ""){
        return (
            <></>
        )
    }
    else {
        return (

            <div className={`info__item ${props.classes ? props.classes : ''}`}>
                <span className="info__label">{props.item.label}</span>
                <div className="info__text" dangerouslySetInnerHTML={{__html: props.value ? props.value : props.item.value}}></div>
            </div>
        )
    }
}

function FileItem(props){
    if (props.item.value === "" ){
        return <></>
    } else {
        if(props.item.value.length === 0){
            return <></>
        } else {
            return (
                <div className={`info__item file__item ${props.classes ? props.classes : ''}`}>
                    <span className="form-added-item__title">{props.item.label}</span>
                    {props.item.type === "nested_files"
                        ? props.item.value.map((item, idx) => {
                            return (
                                <a className="file__item_wrap" key={idx} href={getDomain() + decodeURIComponent(item.file)}
                                   download={true}>
                                    <span className="file-label">{getFileName(item.file)}</span>
                                </a>
                            )
                        })
                        : props.item.value
                            ? <a className="file__item_wrap" href={getDomain() + decodeURIComponent(props.item.value)}
                                 download={true}>
                                <span className="file-label">{getFileName(props.item.value)}</span>
                    </a>
                            : ""
                    }
                </div>
            )
        }
    }
}

function ContagentSurchItem(props){
    const [displayName, setDisplayName] = useState(''),
    contragent_url = routes.filter(r => r.name === "counterparty")

    useEffect(() => {
        if (props.field.value) {
            const url = `${getDomain()}${contragent_url[0]?.url}${props.field.value}`
            getItems(url, 'GET', (data) => {
                setDisplayName(data.fio ? data.fio : data.org_name)
            })
        }
    }, [])

    return(
        <div className={`info__item ${props.classes ? props.classes : ''}`}>
                <span className="info__label">{props.field.label}</span>
                <div className="info__text">{displayName}</div>
            </div>
    )
}

function NestedItem(props){
    const fields = props.field.choices.filter(choice => props.field.value.includes(choice.value))

    return(
        <>
            {fields.map((field, idx) => {
                return (
                    <div key={idx} className={`info__item ${props.classes ? props.classes : ''}`}>
                        <span className="info__label">{props.field.label}</span>
                        <p className="info__text">{field.label}</p>
                    </div>
                )
            })}
        </>
    )
}


export {InfoItem, FileItem}