import React from 'react';
import '../../../styles/CalendarBooking.scss'

export default function AddCalendarBookingButton() {
    const url = '/calendar-booking/create'

    return (
        <div className={'calendar-booking-header'}>
            <a href={url} className={'btn'}>Создать бронирование</a>
        </div>
    )
}