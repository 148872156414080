import React, {useState} from 'react'
import ViewPage from '../../components/ViewPage'
import {getDomain} from "../../functions/functions";
import {checkClientShowExcelFileRequest} from "./api";
import Alert from "../../components/Allert";

const ClientTaskView = (props) => {
    const [alert, setAlert] = useState({})

    const DownloadClientShowFile = () => {
        const onClick = async () => {
            const response = await checkClientShowExcelFileRequest()

            if (response) {
                const url = `${getDomain()}/api/client_show/get_excel`
                const a = document.createElement('a');
                a.href = url;
                a.click();
            } else {
                setAlert({type: 'error', title: 'Ошибка', text: 'Нет назначенных показов'})
            }
        }

        return (
            <div className={'client-show-header-actions-button'}>
                <button onClick={onClick} className={'btn'}>Скачать график показа участков</button>
            </div>
        )
    }

    const ActGeneratorButton = () => {
        const url = `/client-show-generate-acts`
        return (
            <div className={'client-show-header-actions-button'}>
                <a href={url} className={'btn'}>Сгенерировать Акт</a>
            </div>
        )
    }

    return (
        <>
            <ViewPage {...props} addition_components={(
                <div className={'client-show-header-actions'}>
                    <ActGeneratorButton/>
                    <DownloadClientShowFile/>
                </div>
            )}/>
            <Alert setAlert={setAlert} alert={alert}/>
        </>
    )
}

export default ClientTaskView