import React from "react";
import {InfoItem} from "../../../components/InfoItem";
import DocumentAddFormItem from "../../../components/form/DocumentAddFormItem";
import {sendClientShowRequest} from "../api";
import {getDomain} from "../../../functions/functions";
import TableToPDFButton from './TableToPDFButton'

export default function ClientShowAddAct({
                                             meeting_date_field,
                                             meeting_place_field,
                                             responsible_field,
                                             start_date_field,
                                             files_field,
                                             onSuccess,
                                             onNewDateClick,
                                             onCancelClick,
                                             client_show_id,
                                             docData,
                                             callAlert,
                                         }) {
    return (
        <form className={'client-show-detail_form _full'}
              onSubmit={async (e) => {
                  e.preventDefault()
                  const formData = new FormData(e.currentTarget)
                  const files = JSON.parse(formData.get('files'))
                  files_field.value = files

                  if (files.length < 1) {
                      callAlert && callAlert('error', 'Ошибка', 'Добавьте файл \"Акт осмотра\"')
                      return
                  }

                  const response = await sendClientShowRequest(`${getDomain()}/api/client_show/${client_show_id}/execute/`, {
                      files: files,
                  }, 'POST')

                  if (response && onSuccess) {
                      onSuccess()
                  }
              }}>
            <InfoItem item={start_date_field} classes={'client-show-detail-info-item-disabled'}/>
            <InfoItem item={responsible_field} classes={'client-show-detail-info-item-disabled'}/>
            <InfoItem item={meeting_date_field} classes={'client-show-detail-info-item-disabled'}/>
            <InfoItem item={meeting_place_field} classes={'client-show-detail-info-item-disabled'}/>
            <TableToPDFButton {...docData}/>
            <div className={'client-show-detail_actions_list'}>
                <button type={'submit'} className={'btn _cyan fill_container fix_style'}>Выполнено</button>
                <button type={'button'} onClick={onNewDateClick}
                        className={'btn _outline fill_container fix_style'}>Новая дата
                </button>
                <button type={'button'} onClick={onCancelClick}
                        className={'client-show-detail_red_button fill_container fix_style'}>Отклонить
                </button>
            </div>
            <DocumentAddFormItem field={{...files_field, label: 'Акт осмотра'}} classes="_full"/>
        </form>
    )
}