import React, {useState, useEffect, useRef, useMemo} from 'react'
import {DayPicker} from 'react-day-picker';
import {ru} from 'date-fns/locale';


import {onDateTimeInput} from "../../functions/functions"
import getFormattedDate from '../../functions/getFormattedDate';
import {dateValidate} from '../../functions/functions'

const DateItem = ({
                      field,
                      type,
                      error,
                      value,
                      required,
                      blurHandle,
                      fieldDirty,
                      readOnly,
                      setValue,
                      setError,
                      formErrors,
                      setFormErrors,
                      inputRef,
                      timeStep
                  }) => {
    const dataArray = useMemo(() => {
        return value?.split(" ")
    }, [value])
    const [date, setDate] = useState(dataArray?.length > 0 ? dataArray[0] : null)
    const [time, setTime] = useState(dataArray?.length > 1 ? dataArray[1] : null)


    const [selected, setSelected] = useState()
    const [datePicker, setDatePicker] = useState(false)
    const input = inputRef // useRef()
    const picker = useRef()


    const selectDate = (date) => {
        if (date) {
            let date_str
            date_str = getFormattedDate(date)
            setError(!dateValidate(date_str))
            setDate(date_str)
            setSelected(date)
            setDatePicker(false)
        } else {
            setSelected(date)
            setDate('')
            setError(required ? 'Обязательное поле' : '')
            setDatePicker(false)
        }
    }

    const onTimeInput = (e, setValue, setError) => {


        setTime(e.target.value)
    }

    useEffect(() => {
        document.addEventListener('click', (e) => {
            const target = e.target
            const its_datePicker = target == picker.current || picker.current?.contains(target);
            const its_input = target == input.current
            if (!its_datePicker && !its_input) {
                setDatePicker(false)
            }
        })
    }, [])

    useEffect(() => {
        setValue(`${date ? date : ''}${time ? " " + time : ''}`)
    }, [date, time])

    return (
        <>
            <div className={`date-widget${datePicker ? '' : ' d-none'}`} ref={picker}>
                <DayPicker
                    mode="single"
                    selected={selected}
                    onSelect={selectDate}
                    showOutsideDays
                    locale={ru}
                />


            </div>
            <input type='hidden' name={field.name} value={value}/>
            <div className='d-flex gap-8'>
                <input type="text" id={field.id}
                       ref={input}
                       value={date ? date : ''}
                       onChange={e => onDateTimeInput(e, setDate, setError, formErrors, setFormErrors)}
                       onClick={!readOnly ? () => setDatePicker(!datePicker) : () => {
                       }}
                       onBlur={blurHandle}
                       autoComplete="off"
                       className={`date-mask${(fieldDirty && error) ? ' error' : ''}`}
                    // name={field.name}
                       placeholder={field.placeholder}
                       required={required}
                       readOnly={readOnly}/>

                {type.includes('time') &&

                    <input type="time" id={field.id}
                           step={timeStep}
                           value={time ? time : ''}
                           onChange={e => onTimeInput(e, setTime, setError)}
                           onClick={!readOnly ? () => setDatePicker(!datePicker) : () => {
                           }}
                           onBlur={blurHandle}
                           autoComplete="off"
                           className={`date-mask${(fieldDirty && error) ? ' error' : ''}`}
                        // name={field.name}
                           placeholder={field.placeholder}
                           required={required}
                           readOnly={readOnly}/>
                }
            </div>
        </>
    )
}

export default DateItem