import {getDomain} from "../../../functions/functions";

export default async function getActFileRequest(date, microdistrict_id) {
    const url = getDomain() + `/api/client_show/generate_excel_general_act/?date=${date}&microdistrict_id=${microdistrict_id}`
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'application/json'
        },
        credentials: "same-origin",
    })

    return {ok: !!response.ok, data: await response.blob()}
}