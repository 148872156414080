import React, {useEffect, useState} from 'react';
import '../../../styles/CalendarBookingScreenPage.scss'
import {getCalendarBookingList} from "../../CalendarBooking/api";
import BookingMainCart from "./BookingMainCart";
import BookingMiniCart from "./BookingMiniCart";
import rightArraySvg from '../../../../public/interface_img/arrow-right.svg'

const getNowDate = () => {
    const date = new Date()
    return date.toISOString()
}

const getTimeInMinutes = (time) => {
    const [hh, mm] = time.split(':')
    return Number(hh) * 60 + Number(mm)
}

const compDates = (a, b) => {
    const aTime = a?.split(" ")[1]
    const bTime = b?.split(" ")[1]
    return getTimeInMinutes(aTime) - getTimeInMinutes(bTime)
}


const getTime = (time) => {
    try {
        return time.split(" ")[1] || ''
    } catch (e) {
        return ''
    }
}

const getCartProps = (event) => {
    const convertFio = (fio) => {
        const [l, f, p] = fio.split(" ")
        return `${l} ${f[0] + '.' || ''} ${p[0] + '.' || ''}`
    }
    return {
        responsible: convertFio(event?.responsible),
        startTime: getTime(event?.booking_time),
        endTime: getTime(event?.end_booking_time),
        title: event?.title || '',
    }
}

const sortEvents = (events) => {
    return (events || []).sort((a, b) => compDates(a.booking_time, b.booking_time))
}

const filterEvents = (events) => {
    const date = new Date()
    const dateNow = `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`
    return (events || []).filter(item => getTimeInMinutes(item.endTime) > getTimeInMinutes(dateNow))
}

const addEmptyCart = (events) => {
    if (events && events.length > 0) {
        const newEvents = []
        if (getTimeInMinutes(getTime(events[0].booking_time)) > getTimeInMinutes('09:00')) {
            newEvents.push({
                title: 'Свободно',
                startTime: '09:00',
                endTime: getTime(events[0].booking_time),
                empty: true,
            })
        }

        newEvents.push(getCartProps(events[0]))

        for (let i = 1; i < events.length; i++) {
            const prevEndDate = events[i - 1].end_booking_time
            const curStartDate = events[i].booking_time
            if (prevEndDate !== curStartDate) {
                newEvents.push({
                    title: 'Свободно',
                    startTime: getTime(prevEndDate),
                    endTime: getTime(curStartDate),
                    empty: true,
                })
            }
            newEvents.push(getCartProps(events[i]))
        }

        if (newEvents.length > 0) {
            const lastEvent = newEvents[newEvents.length - 1]
            if (getTimeInMinutes(lastEvent.endTime) < getTimeInMinutes('19:00')) {
                newEvents.push({
                    title: 'Свободно',
                    startTime: lastEvent.endTime,
                    endTime: '19:00',
                    empty: true,
                })
            }
        }

        return newEvents
    }
    return []
}

export default function CalendarBookingScreenPage({floor} = {floor: 1}) {
    const [events, setEvents] = useState([])
    const [curDateTime, setCurDateTime] = useState(getNowDate())
    const [isEmptyDay, setIsEmptyDay] = useState(true)

    useEffect(() => {
        let timeout
        const TIMEOUT_DURATION = 1000
        const updateDate = () => {
            setCurDateTime(getNowDate())
            timeout = setTimeout(updateDate, TIMEOUT_DURATION)
        }

        timeout = setTimeout(updateDate, TIMEOUT_DURATION)
        return () => {
            timeout && clearTimeout(timeout)
        }
    }, []);

    useEffect(() => {
        const curMinutes = Number((new Date(curDateTime)).getMinutes())
        if (curMinutes === 0 || curMinutes === 30) {
            setEvents(prevState => filterEvents(prevState))
        }
    }, [curDateTime]);

    useEffect(() => {
        const getEvents = async () => {
            const curDate = new Date(curDateTime)
            const filterDate = `${String(curDate.getDate()).padStart(2, '0')}.${String(curDate.getMonth() + 1).padStart(2, '0')}.${curDate.getFullYear()}`
            const response = await getCalendarBookingList({
                meeting_room: `Этаж ${floor}`,
                token: false,
                date_before: filterDate,
                date_after: filterDate
            });
            if (response?.results) {
                setIsEmptyDay(response.results?.length === 0)
                const newEvents = filterEvents(addEmptyCart(sortEvents(response.results)))
                setEvents(newEvents)
            }
        }
        let timeout
        const TIMEOUT_DURATION = 1000 * 60 * 1
        const updateEvents = () => {
            getEvents()
            timeout = setTimeout(updateEvents, TIMEOUT_DURATION)
        }

        updateEvents()
        return () => {
            timeout && clearTimeout(timeout)
        }
    }, []);

    const getContent = () => {
        if (isEmptyDay) {
            return (
                <div className={'calendar-booking-screen-page-main-not-events'}>
                    <div className={'calendar-booking-screen-page-main-not-events-content'}>
                        <span className={'calendar-booking-screen-page-main-not-events'}>Мероприятий</span>
                        <span className={'calendar-booking-screen-page-main-not-events'}>не запланировано</span>
                    </div>
                </div>
            )
        }
        return (
            <div className={'calendar-booking-screen-next-events-block'}>
                <div className={'calendar-booking-screen-next-events-main-cart-block'}>
                    <BookingMainCart {...events[0]} full={events.length === 1}/>
                </div>
                {events.length > 1 && <div className={'calendar-booking-screen-next-events-mini-cart-block'}>
                    <span className={'calendar-booking-screen-next-events-mini-cart-block-label'}>
                        Далее <img src={rightArraySvg}/>
                    </span>
                    <div className={'calendar-booking-screen-next-events-mini-cart-block-list'}>
                        {events.slice(1, events.length).map((item, idx) => <BookingMiniCart {...item}
                                                                                            full={events.length === 2}
                                                                                            key={idx}/>)}

                    </div>
                </div>}
            </div>
        )
    }

    const getDate = () => {
        const date = new Date(curDateTime);
        const day = date.getDate();
        const monthNames = [
            'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
            'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
        ];
        const month = monthNames[date.getMonth()];
        const year = date.getFullYear();

        return `${day} ${month} ${year}`;
    };

    const getTime = () => {
        const date = new Date(curDateTime);
        return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;

    }

    return (
        <div className={'calendar-booking-screen-page'}>
            <div className={'calendar-booking-screen-page-header'}>
                <div className={'calendar-booking-screen-page-header-label'}>Переговорная {floor} этаж</div>
                <div className={'calendar-booking-screen-page-header-date-time-block'}>
                    <span className={'calendar-booking-screen-page-header-date-time-block-date'}>{getDate()}</span>
                    <span className={'calendar-booking-screen-page-header-date-time-block-time'}>{getTime()}</span>
                </div>
            </div>
            {/*<div className={'calendar-booking-screen-page-main'}>*/}
            {getContent()}
            {/*</div>*/}
        </div>
    )
}