import React from 'react';
import ClientShowModuleWrapper from "./ClientShowModuleWrapper";
import {InfoItem} from "/src/components/InfoItem";

export default function ClientShowCancelStatus({responsible_field, decline_reason_field,}) {
    return (
        <ClientShowModuleWrapper status={'Отклонено'}>
            <InfoItem item={responsible_field} classes={'client-show-detail-info-item-disabled'}/>
            <InfoItem item={decline_reason_field}  classes={'client-show-detail-info-item-disabled'}/>
        </ClientShowModuleWrapper>
    )
}