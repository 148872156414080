import React from 'react';
import ClientShowModuleWrapper from "./ClientShowModuleWrapper";
import {FileItem, InfoItem} from "/src/components/InfoItem";

export default function ClientShowCompletedStatus({responsible_field, files_field,}) {
    console.log('files_field', files_field);
    return (
        <ClientShowModuleWrapper status={'Выполнено'}>
            <InfoItem item={responsible_field} classes={'client-show-detail-info-item-disabled'}/>
            <FileItem item={files_field} classes={'client-show-detail-info-item-disabled'}/>
        </ClientShowModuleWrapper>
    )
}