import React from 'react';

export default function ClientShowModuleWrapper({children, status}) {
    return (
        <div className={'page__info-container client-tasks-client-show-module-wrapper'}>
            <span className={'client-tasks-client-show-module-wrapper__header'}>Статус показа участка</span>
            <span className={'client-tasks-client-show-module-wrapper__status'}>{status}</span>
            {children}
        </div>
    )
}