import React, {useEffect, useRef} from 'react';
import '../../../styles/TimeSelector.scss'

const ChooseFeed = ({arr, value, onClick = () => undefined, isActive = (_time) => false}) => {
    const compRef = useRef(null);
    const scrollTopRef = useRef(0);

    useEffect(() => {
        if (compRef.current) {
            compRef.current.scrollTop = scrollTopRef.current;
        }
    }, [value]);

    useEffect(() => {
        if (compRef.current) {
            const targetItem = arr.find(item => item.value === value);
            if (targetItem) {
                const index = arr.indexOf(targetItem);
                const itemHeight = compRef.current.children[0].offsetHeight; // Assuming all items have the same height
                console.log(itemHeight, index)
                compRef.current.scrollTop = index * itemHeight;
            }
        }
    }, []);

    const onScroll = (e) => {
        const {scrollTop} = e.target;
        scrollTopRef.current = scrollTop;
    }

    return (
        <div ref={compRef} onScroll={onScroll} className={'calendar-booking-time-selector-choose-feed'}>
            {arr.map((item, idx) => {
                return <button onClick={() => onClick(item?.value)}
                               type={'button'}
                               className={`calendar-booking-time-selector-choose-feed-item  ${isActive(item?.value) ? 'active' : ''}`}
                               key={idx}>
                    {item?.label}
                </button>
            })}
        </div>
    )
}

export default function TimeSelector({value, onChange, minutes = true, hours = true, hoursStep = 1, minutesStep = 1}) {
    const onChangeHours = (curHours) => {
        if (!minutes) {
            onChange && onChange(curHours)
        } else {
            const curMinutes = getMinutes(value)
            onChange && onChange(`${curHours}:${curMinutes}`)
        }
    }

    const onChangeMinutes = (curMinutes) => {
        if (!hours) {
            onChange && onChange(curMinutes)
        } else {
            const curHours = getHours(value)
            onChange && onChange(`${curHours}:${curMinutes}`)
        }
    }

    const getHours = (time) => {
        if (time) {
            const regex = /(\d{0,2})?:(\d{0,2})/
            const matches = time.match(regex);
            return String(matches[1]).padStart(2, '0')
        }
    }

    const getMinutes = (time) => {
        if (time) {
            const regex = /(\d{0,2})?:(\d{0,2})/
            const matches = time.match(regex);
            return String(matches[2]).padStart(2, '0')
        }
    }

    const isActiveMinutes = (curMinutes) => {
        return curMinutes === getMinutes(value)
    }

    const isActiveHours = (curHours) => {
        return curHours === getHours(value)
    }

    const getMinutesArr = () => {
        return Array.from({length: Math.floor(60 / minutesStep)}, (_, i) => {
                const minutes = String(i * minutesStep).padStart(2, '0')
                return {
                    value: minutes,
                    label: minutes
                }
            }
        )
    }

    const getHoursArr = () => {
        return Array.from({length: Math.floor(24 / hoursStep)}, (_, i) => {
                const hours = String(i * hoursStep).padStart(2, '0')
                return {
                    value: hours,
                    label: hours
                }
            }
        )
    }

    return (
        <div className={'calendar-booking-time-selector'}>
            {hours && <ChooseFeed value={getHours(value)} arr={getHoursArr()} onClick={onChangeHours} isActive={isActiveHours}
                                  key={'hours'}/>}
            {minutes &&
                <ChooseFeed value={getMinutes(value)} arr={getMinutesArr()} onClick={onChangeMinutes} isActive={isActiveMinutes}
                            key={'minutes'}/>}
        </div>
    )
}