import {getDomain} from "../../../functions/functions";

export default async function getCalendarBookingList({options, meeting_room, date_before, date_after, token = true} = {
    options: false,
    date_after: '',
    date_before: '',
    meeting_room: ''
}) {
    const url = getDomain() + `/api/calendar/booking/?date_after=${date_after || ''}&date_before=${date_before || ''}&meeting_room=${meeting_room || ''}`
    const response = await fetch(url, {
        method: options ? 'OPTIONS' : 'GET',
        headers: {
            ...(token ? {'Authorization': `Bearer ${localStorage.getItem("token")}`}: {}),
            'Content-Type': 'application/json'
        },
        credentials: "same-origin",
    })
    if (response.ok) {
        return response.json()
    }
    return null
}