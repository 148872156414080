import {getDomain} from "../../../functions/functions";

export default async function getMicrodistrictOptionsRequest() {
    const url = getDomain() + '/api/geo/microdistrict/'
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'application/json'
        },
        credentials: "same-origin",
    })

    return {ok: !!response.ok, data: await response.json()}
}