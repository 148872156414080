import React, {useState} from 'react';
import chevronDownSvg from '../../../../public/interface_img/chevron-down.svg'
import chevronUpSvg from '../../../../public/interface_img/chevron-up.svg'
import '../../../styles/CalendarBooking.scss'

const convertValue = (value) => {
    if (value === undefined || value === null) {
        return 30
    }
    const minutes = Number(value)
    if (minutes % 30 !== 0) {
        return minutes - minutes % 30
    }
    return minutes
}

export default function TimeSwitcher({startValue, label, name, required = false}) {
    const [value, setValue] = useState(convertValue(startValue))

    const onUpClick = () => {
        setValue(prevState => prevState + 30)
    }

    const onDownClick = () => {
        setValue(prevState => {
            if (prevState !== undefined && prevState !== null) {
                if (prevState >= 30) {
                    return prevState - 30
                }
                return prevState
            }
            return 30
        })
    }

    const onChange = (e) => {
        const newValue = String(e.target.value).replace(/\D/g, '')
        setValue(newValue)
    }

    return (
        <div className={'form__item _full time'}>
            <div className={'calendar-booking-time-switcher'}>
                <input name={name} required={required} value={value} onChange={onChange} type={"text"}
                       onBlur={() => setValue(prevState => convertValue(prevState))}/>
                <div className={'calendar-booking-time-switcher-buttons'}>
                    <img onClick={onUpClick} src={chevronUpSvg}
                         className={'calendar-booking-time-switcher-button-item'}/>
                    <img onClick={onDownClick} src={chevronDownSvg}
                         className={'calendar-booking-time-switcher-button-item'}/>
                </div>
            </div>
            <label htmlFor={name}>{label}{required ? <span>*</span> : ''}</label>
        </div>
    )
}