import React from 'react';
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts';
// pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function TableToPDFButton({
                                             conteragent_fio,
                                             executor_fio,
                                             conteragent_phone,
                                             landplot_name,
                                             cadastral_number,
                                         }) {

    const getWidths = () => {
        const arr = [0.75, 8, 6, 5, 4, 3, 5]
        const k = 22.5
        return arr.map(item => item * k)
    }

    const generatePDF = () => {
        const docDefinition = {
            pageOrientation: 'landscape',
            content: [
                {
                    table: {
                        headerRows: 1,
                        widths: getWidths(),
                        body: [
                            [
                                {
                                    text: 'Акт приема - передачи границ земельного участка',
                                    colSpan: 7,
                                    // style: 'title',
                                    alignment: 'center'
                                },
                                {}, {}, {}, {}, {}, {}
                            ],
                            [
                                {
                                    text: landplot_name,
                                    colSpan: 7,
                                    // style: 'subtitle',
                                    alignment: 'center'
                                },
                                {}, {}, {}, {}, {}, {}
                            ],
                            [
                                {
                                    text: 'Границы участка установил:',
                                    colSpan: 2,
                                    rowSpan: 2,
                                    alignment: 'center',
                                    justifyItems: 'center',
                                },
                                {},
                                {
                                    text: 'Ф.И.О.',
                                    alignment: 'center',
                                    colSpan: 2,
                                },
                                {},
                                {text: 'Телефон', alignment: 'center', colSpan: 2},
                                {},
                                {text: 'Подпись', alignment: 'center'}
                            ],
                            [
                                {},
                                {},
                                {
                                    text: '\n',
                                    alignment: 'center',
                                    colSpan: 2,
                                },
                                {},
                                {
                                    text: '',
                                    alignment: 'center',
                                    colSpan: 2,
                                },
                                {},
                                {}
                            ],
                            [
                                {
                                    text: 'Специалист отдела продаж:',
                                    colSpan: 2,
                                    alignment: 'center',
                                },
                                {},
                                {
                                    text: executor_fio,
                                    colSpan: 2,
                                    alignment: 'center',
                                }, {},
                                {
                                    text: '',
                                    alignment: 'center',
                                    colSpan: 2,
                                },
                                {}, {}
                            ],
                            [
                                {text: '№ п.п', alignment: 'center', style: 'numberCol', justifySelf: 'center',},
                                {text: 'Ф.И.О.', alignment: 'center', justifySelf: 'center',},
                                {
                                    text: 'Кадастровый номер земельного участка',
                                    alignment: 'center',
                                    justifySelf: 'center',
                                },
                                {text: 'Телефон', alignment: 'center', justifySelf: 'center',},
                                {text: 'Дата и время', colSpan: 2, alignment: 'center', justifySelf: 'center'}, {},
                                {text: 'Подпись', alignment: 'center', justifySelf: 'center'}
                            ],
                            [
                                {
                                    text: '1',
                                    style: 'numberCol',
                                    alignment: 'center',
                                },
                                {
                                    text: conteragent_fio,
                                    alignment: 'center',
                                },
                                {
                                    text: cadastral_number,
                                    alignment: 'center',
                                },
                                {
                                    text: conteragent_phone,
                                    alignment: 'center',
                                },
                                {},
                                {},
                                {},
                            ]
                        ]
                    },
                }
            ],
            styles: {
                title: {
                    fontSize: 14,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 5, 0, 10]
                },
                subtitle: {
                    fontSize: 12,
                    alignment: 'center',
                    margin: [0, 5, 0, 10]
                },
                numberCol: {
                    width: 10,
                }
            },
            defaultStyle: {
                font: 'Roboto',
                fontSize: 10,
                alignment: 'center',
                justifyItems: 'center',
            }
        };

        pdfMake.createPdf(docDefinition).download('Акт приема.pdf');
    };

    return (
        <button type={'button'} onClick={generatePDF} className="btn">
            Скачать акт в PDF
        </button>
    );
}