import React, {useEffect, useState} from 'react';
import Modal from 'react-modal';
import '../styles/OverduePopup.scss';
import clockIcon from '../../public/interface_img/clock-fast-forward.svg';
import {getDomain} from '../functions/functions';
import {useDispatch, useSelector} from "react-redux";
import {setIsActive, setIsRedirect} from '../store/overduePopupSlice';
import {NavLink} from "react-router-dom";
import {deleteFilterData, setFilterData} from "../store/filtersSlice";

const OverduePopup = () => {
    const dispatch = useDispatch();
    const modalIsOpen = useSelector(state => state.overduePopup.isActive);
    const userData = useSelector(state => state.user_data.userData);
    const modalIsRedirect = useSelector(state => state.overduePopup.isRedirect);

    const setModalIsOpen = (isActive) => dispatch(setIsActive({isActive}));
    const setModalIsRedirect = (isRedirect) => dispatch(setIsRedirect({isRedirect}));

    const setFiltersData = (filtersData) => dispatch(setFilterData(filtersData));

    const [isView, setIsView] = useState(false);

    const [data, setData] = useState({
        count_overdue_tasks: 0,
        count_overdue_agreement: 0,
        count_overdue_service_records: 0,
        count_overdue_incoming: 0,
    });

    const fetchOverdueData = async () => {
        try {
            const response = await fetch(`${getDomain()}/api/main/overdue/?user_id=${userData.user_id}`);
            const result = await response.json();
            setData(result);

            setIsView(Object.values(result).some(count => count && count > 0));
        } catch (error) {
            console.error('Error fetching overdue data:', error);
        }
    };

    useEffect(() => {
        if (modalIsRedirect) {
            setModalIsRedirect(false);
        } else {
            setModalIsOpen(true);
        }
    }, [modalIsOpen]);

    const handleRedirect = (filter = []) => {
        setFiltersData(filter);
        setModalIsRedirect(true);
        setModalIsOpen(false);
    };

    const handleHide = (seconds) => {
        const hideUntil = new Date(Date.now() + seconds * 1000);
        localStorage.setItem('hideOverduePopupUntil', hideUntil.toISOString());
        setModalIsOpen(false);
        setModalIsRedirect(true);
        setTimeout(() => {
            setModalIsOpen(true);
        }, seconds * 1000);
    };

    useEffect(() => {
        const hideUntil = localStorage.getItem('hideOverduePopupUntil');
        if (hideUntil) {
            const hideUntilDate = new Date(hideUntil);
            const now = new Date();
            if (now < hideUntilDate) {
                setModalIsOpen(false);
                setTimeout(() => {
                    setModalIsOpen(true);
                }, hideUntilDate - now);
                return;
            }
        }

        fetchOverdueData();
    }, []);

    const renderTaskItem = (title, count, link, filter) => {
        if (count === 0) return null;

        return count && (
            <div className="task-item">
                <span>{title}</span>
                <div className="counter-link">
                    <span className="counter">{count}</span>
                    <NavLink to={`/${link}`} className="link" onClick={() => handleRedirect([link, filter])}>
                        Перейти
                    </NavLink>
                </div>
            </div>
        );
    };

    return (
        isView && (
            <Modal className="overdue-popup" overlayClassName="overdue-popup-overlay" isOpen={modalIsOpen}
                   shouldCloseOnOverlayClick={false}>
                <div>
                    <p className="alert">Внимание!</p>
                    <p className="alert-description">У вас есть незавершенные дела</p>
                </div>
                <div className="task-list">
                    {renderTaskItem('Просроченные задачи', data.count_overdue_tasks, 'my-tasks', [{for_date: 1}, {receiver: userData.user_id}])}
                    {renderTaskItem('Согласование не завершено', data.count_overdue_agreement, 'my-agreement', [{user_status: "На согласовании"}])}
                    {renderTaskItem('Служебные записки, требующие внимания', data.count_overdue_service_records, 'my-service-records', [{status: 3}, {receiver: userData.user_id}])}
                    {renderTaskItem('Просроченные входящие письма', data.count_overdue_incoming, 'my-incoming', [{out_doc: 2}, {responsible: userData.user_id}])}
                </div>
                <div className='button-flex'>
                    <button onClick={() => handleHide(3600)}>
                        <div><img src={clockIcon} className="icon" alt="clock icon"/></div>
                        <div>Скрыть на 1 час</div>
                    </button>
                    <button onClick={() => handleHide(8 * 3600)}>
                        <div><img src={clockIcon} className="icon" alt="clock icon"/></div>
                        <div>Скрыть на 8 часов</div>
                    </button>
                </div>
            </Modal>
        )
    );
};

export default OverduePopup;
