import {InfoItem} from "../../../components/InfoItem";
import FormItem from "../../../components/form/FormItem";
import {sendClientShowRequest} from "../api";
import {getDomain} from "../../../functions/functions";
import FormErrorsContext from "../../../functions/formErrorsContext";
import React, {useState} from "react";

export default function ClientShowEditDataPlace({
                                                    phone,
                                                    meeting_date_field,
                                                    meeting_place_field,
                                                    responsible_field,
                                                    start_date_field,
                                                    client_show_id,
                                                    onSuccess,
                                                    onCancel,
                                                    callAlert,
                                                }) {
    const [formErrors, setFormErrors] = useState()

    return (
        <FormErrorsContext.Provider value={{formErrors, setFormErrors}}>
            <form className={'client-show-detail_form _full'}
                  onSubmit={async (e) => {
                      e.preventDefault()
                      const formData = new FormData(e.currentTarget)
                      const meeting_date = formData.get('meeting_date')
                      const meeting_place = formData.get('meeting_place')
                      meeting_date_field.value = meeting_date
                      meeting_place_field.value = meeting_place

                      if (!meeting_place || !meeting_date) {
                          callAlert && callAlert('error', 'Ошибка', 'Заполните поля')
                          return
                      }

                      let response
                      let status

                      const success = await sendClientShowRequest(`${getDomain()}/api/client_show/${client_show_id}/change_time_show/`, {
                          phone: phone,
                          new_date: meeting_date,
                          new_place: meeting_place,
                      }, 'POST',  (data) => response = data, (newStatus) => status = newStatus)
                      console.log(status, response)
                      if (Number(status) >= 400)
                          callAlert( 'error', 'Ошибка', response.detail)

                      if (success && onSuccess) {
                          callAlert('success',  'Успешно', response.detail)
                          onSuccess()
                      }
                  }}>
                <InfoItem item={start_date_field} classes={'client-show-detail-info-item-disabled'}/>
                <InfoItem item={responsible_field} classes={'client-show-detail-info-item-disabled'}/>
                <div className={'client-show-detail_form_edited_fields'}>
                    <FormItem field={meeting_date_field} required/>
                    <FormItem field={meeting_place_field}/>
                </div>
                <div className={'client-show-detail_actions_list'}>
                    <button type={'submit'} className={'btn _outline fill_container fix_style'}>Подтвердить</button>
                    <button type={'button'} onClick={onCancel}
                            className={'client-show-detail_red_button fill_container fix_style'}>Отменить
                    </button>
                </div>
            </form>
        </FormErrorsContext.Provider>
    )
}