export default function setUserPermissions(data, addUserData) {
    const newGroups = []
    const permissions = []
    if (data.groups) {
        for (const group of data.groups) {
            try {
                for (const permission of group.permissions) {
                    permissions.push(permission.codename)
                }
            } catch (error) {
                console.log('permissions error', group, error)
            }
            newGroups.push(group.name)
        }

        localStorage.setItem("groups", JSON.stringify(newGroups))
        addUserData(['groups', JSON.stringify(newGroups)])

        localStorage.setItem("permissions", JSON.stringify(permissions))
        addUserData(['permissions', JSON.stringify(permissions)])

        localStorage.setItem("status", JSON.stringify(data.status))
        addUserData(['status', data.status])
    }
}