import {InfoItem} from "../../../components/InfoItem";
import FormItem from "../../../components/form/FormItem";
import {sendClientShowRequest} from "../api";
import {getDomain} from "../../../functions/functions";
import FormErrorsContext from "../../../functions/formErrorsContext";
import React, {useState} from "react";

export default function ClientShowInputCancelReason({
                                                        meeting_date_field,
                                                        meeting_place_field,
                                                        responsible_field,
                                                        start_date_field,
                                                        decline_reason_field,
                                                        client_show_id,
                                                        onSuccess,
                                                        onCancel,
                                                        callAlert,
                                                    }) {
    const [formErrors, setFormErrors] = useState()

    return (
        <FormErrorsContext.Provider value={{formErrors, setFormErrors}}>
            <form className={'client-show-detail_form _full'}
                  onSubmit={async (e) => {
                      e.preventDefault()
                      const formData = new FormData(e.currentTarget)
                      const decline_reason = formData.get('decline_reason')
                      decline_reason_field.value = decline_reason

                      if (!decline_reason) {
                          callAlert && callAlert('error', 'Ошибка', 'Заполните поле причины отклонения')
                          return
                      }
                      let response
                      const success = await sendClientShowRequest(`${getDomain()}/api/client_show/${client_show_id}/reject/`, {
                          decline_reason: decline_reason,
                      }, 'POST', data => response = data)
                      if (success && onSuccess) {
                          callAlert && callAlert('info', 'Информация', response.detail)
                          onSuccess()
                      } else {
                          callAlert && callAlert('error', 'Ошибка', response.detail)
                      }
                  }}>
                <InfoItem item={start_date_field} classes={'client-show-detail-info-item-disabled'}/>
                <InfoItem item={responsible_field} classes={'client-show-detail-info-item-disabled'}/>
                <InfoItem item={meeting_date_field} classes={'client-show-detail-info-item-disabled'}/>
                <InfoItem item={meeting_place_field} classes={'client-show-detail-info-item-disabled'}/>
                <div className={'client-show-detail_form_edited_fields'}>
                    <FormItem field={decline_reason_field}/>
                </div>
                <div className={'client-show-detail_actions_list'}>
                    <button type={'submit'}
                            className={' client-show-detail_red_button fill_container fix_style'}>Подтвердить
                    </button>
                    <button type={'button'} onClick={onCancel}
                            className={'btn _outline fill_container fix_style'}>Отменить
                    </button>
                </div>
            </form>
        </FormErrorsContext.Provider>
    )
}