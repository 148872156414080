import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom"
import {useSelector} from "react-redux"
import {createFieldsObject, getDomain, getItems} from "../../functions/functions";
import CommentComponent from "../../components/CommentCmponent/CommentComponent"
import '/src/styles/ClientShowPage.scss'
import {
    ClientShowAddAct,
    ClientShowDetailChooseResponsible,
    ClientShowEditDataPlace,
    ClientShowInputCancelReason,
    ClientShowInputDataPlace
} from './components'
import {ClientShowCancelStatus, ClientShowCompletedStatus, ClientShowInWorkStatus} from "../clients_tasks/components";
import Alert from "../../components/Allert";
import ClientShowInProgressStatus from "../clients_tasks/components/ClientShowInProgressStatus";
import HistoryComponent from "../../components/HistoryComponent/HistoryComponent";

function ClientTasksDetail(props) {
    const [prefencialTypes, setPrefencialTypes] = useState()
    const [executorName, setExecutorName] = useState()
    const date = new Date();
    const userData = useSelector(state => state.user_data.userData)
    const [alert, setAlert] = useState({})

    const setPreferencialType = (data) => {
        const preferencialChoices = data.actions?.PUT?.client_task?.children?.preferencial.choices.filter(t => props.fields.client_task.value.preferencial.find(item => t.value === item))
        setPrefencialTypes(preferencialChoices)
    }

    const setExecutorType = (data) => {
        const name = data.actions?.PUT?.client_task?.children?.executor.choices.find(item => item.value === props.fields.client_task?.value.executor)
        setExecutorName(name.display_name)
    }

    useEffect(() => {
        getItems(`${getDomain()}/api/client_show/${props.fields.id.value}/`, 'OPTIONS', (data) => {
            // setLandplotType(data)
            setPreferencialType(data)
            setExecutorType(data)
        })
        props.setCanEdit(false)
    }, [])

    const isClientTasks = () => {
        const groups = JSON.parse(userData.groups)
        const managerGroupName = 'ClientTasks'
        return groups.indexOf(managerGroupName) !== -1
    }

    const isClientShow = () => {
        const groups = JSON.parse(userData.groups)
        const managerGroupName = 'Client_show'
        return groups.indexOf(managerGroupName) !== -1
    }

    const isResponsible = () => {
        return Number(userData.user_id) === Number(props.fields.responsible.value)
    }

    const isAdmin = () => {
        const groups = userData.groups ? userData.groups : []
        return groups.indexOf('Admin') !== -1
    }


    const getPreferencialChoicesLabels = () => {
        if (prefencialTypes) {
            const preferencial = props?.fields?.client_task?.value?.preferencial
            const options = prefencialTypes
            return preferencial.map(curPreferencial => {
                const label = options.find(item => {
                    return curPreferencial === item.value
                })
                return label?.display_name
            })
        }
        return []
    }

    const preferencialChoicesLabels = getPreferencialChoicesLabels()

    const LandplotInfo = () => {
        const landplot = props.fields.client_task?.value?.landplot
        return (
            <>
                {props?.fields?.created_at?.value &&
                    <div className={`info__item _full`}>
                        <span className="info__label">Дата создания</span>
                        <div className="info__text">{props?.fields?.created_at?.value}</div>
                    </div>
                }
                {landplot?.condition &&
                    <div className={`info__item _full`}>
                        <span className="info__label">Условия приобретения</span>
                        <div className="info__text">{landplot?.condition}</div>
                    </div>
                }
                {landplot?.type &&
                    <div className={`info__item _full`}>
                        <span className="info__label">Тип участка</span>
                        <div className="info__text">{landplot?.type}</div>
                    </div>
                }
                {landplot?.number &&
                    <div className={`info__item _full`}>
                        <span className="info__label">Номер участка</span>
                        <div className="info__text">{landplot?.number}</div>
                    </div>
                }
                {landplot?.cadastral &&
                    <div className={`info__item _full`}>
                        <span className="info__label">Участок</span>
                        <div className="info__text">{landplot?.cadastral}</div>
                    </div>
                }
                {landplot?.area &&
                    <div className={`info__item _full`}>
                        <span className="info__label">Площадь земельного участка</span>
                        <div className="info__text">{landplot?.area}</div>
                    </div>
                }
                {landplot?.microdistrict &&
                    <div className={`info__item _full`}>
                        <span className="info__label">Микрорайон</span>
                        <div className="info__text">{landplot?.microdistrict}</div>
                    </div>
                }
                {landplot?.microdistrict &&
                    <div className={`info__item _full`}>
                        <span className="info__label">Муниципальное образование</span>
                        <div className="info__text">{landplot?.municipality}</div>
                    </div>
                }
                {landplot?.land_cost &&
                    <div className={`info__item _full`}>
                        <span className="info__label">Стоимость участка (₽)</span>
                        <div className="info__text">{landplot?.land_cost}</div>
                    </div>
                }
                {landplot?.down_payment &&
                    <div className={`info__item _full`}>
                        <span className="info__label">Первоначальный взнос (₽)</span>
                        <div className="info__text">{landplot?.down_payment}</div>
                    </div>
                }
            </>
        )
    }

    const ContragentData = () => {
        const contragent = props.fields.client_task?.value?.contragent

        return (
            <>
                {contragent?.fio &&
                    <div className={`info__item _full`}>
                        <span className="info__label">ФИО</span>
                        <div className="info__text">{contragent?.fio}</div>
                    </div>}
                {contragent?.birth_date &&
                    <div className={`info__item _full`}>
                        <span className="info__label">Дата рождения</span>
                        <div className="info__text">{contragent?.birth_date}</div>
                    </div>}
                {contragent?.phone &&
                    <div className={`info__item _full`}>
                        <span className="info__label">Телефон</span>
                        <div className="info__text">{contragent?.phone}</div>
                    </div>}
                {contragent?.email &&
                    <div className={`info__item _full`}>
                        <span className="info__label">Электронная почта</span>
                        <div className="info__text">{contragent?.email}</div>
                    </div>}
                {contragent?.client &&
                    <div className={`info__item _full`}>
                        <span className="info__label">Действующий клиент</span>
                        <div className="info__text">{contragent?.client ? contragent?.client : 'Нет'}</div>
                    </div>}
                {preferencialChoicesLabels?.length > 0 &&
                    <div className={`info__item _full`}>
                        <span className="info__label">Льгота</span>
                        {preferencialChoicesLabels.map((item, idx) => {
                            return item ? <p className="info__text mb-8" key={idx}>{item}</p> : undefined
                        })}
                    </div>
                }
                {executorName &&
                    <div className={`info__item _full`}>
                        <span className="info__label">Исполнитель</span>
                        <div className="info__text">{executorName}</div>
                    </div>
                }
            </>
        )
    }
    const CLIENT_SHOW_STATES = {
        CHOOSE_RESPONSIBLE: 'CHOOSE_RESPONSIBLE',
        INPUT_DATA_PLACE: 'INPUT_DATA_PLACE',
        ADD_ACT: 'ADD_ACT',
        EDIT_DATE_FIELD: 'EDIT_DATE_FIELD',
        INPUT_CANCEL_REASON: 'INPUT_CANCEL_REASON',
        CLIENT_SHOW_COMPLETE: 'CLIENT_SHOW_COMPLETE',
    }

    const initClientShowState = () => {
        if (props?.fields?.responsible?.value && props?.fields?.meeting_date?.value && props?.fields?.meeting_place?.value) {
            return CLIENT_SHOW_STATES.ADD_ACT
        } else if (props?.fields?.responsible?.value) {
            return CLIENT_SHOW_STATES.INPUT_DATA_PLACE
        }
        return CLIENT_SHOW_STATES.CHOOSE_RESPONSIBLE
    }

    const [clientShowState, setClientShowState] = useState(initClientShowState())

    const ClientShow = () => {
        const isCurResponsible = isResponsible()
        const isCurClientShow = isClientShow()
        const isCurClientTasks = isClientTasks()
        const isCurAdmin = isAdmin()
        const client_show_id = props.fields.id.value
        const status = props.fields.status.value

        if (status === 'Выполнено' || clientShowState === CLIENT_SHOW_STATES.CLIENT_SHOW_COMPLETE) {
            return <ClientShowCompletedStatus responsible_field={props.fields.responsible}
                                              files_field={props.fields.files}/>
        } else if (status === 'Отклонено') {
            return <ClientShowCancelStatus responsible_field={props.fields.responsible}
                                           decline_reason_field={props.fields.decline_reason}/>
        }

        const callAlert = (type, title, text) => {
            setAlert({type: type, title: title, text: text})
        }

        switch (clientShowState) {
            case CLIENT_SHOW_STATES.INPUT_CANCEL_REASON:
                if (!isCurAdmin && !isCurResponsible)
                    return undefined
                return <ClientShowInputCancelReason client_show_id={client_show_id}
                                                    onSuccess={() => {
                                                        props.fields.status.value = 'Отклонено'
                                                        setClientShowState(CLIENT_SHOW_STATES.ADD_ACT)
                                                    }}
                                                    onCancel={() => setClientShowState(CLIENT_SHOW_STATES.ADD_ACT)}
                                                    meeting_date_field={props.fields.meeting_date}
                                                    meeting_place_field={props.fields.meeting_place}
                                                    responsible_field={props.fields.responsible}
                                                    start_date_field={props.fields.start_date}
                                                    decline_reason_field={props.fields.decline_reason}
                                                    callAlert={callAlert}/>
            case CLIENT_SHOW_STATES.EDIT_DATE_FIELD:
                if (!isCurAdmin && !isCurResponsible)
                    return undefined
                return <ClientShowEditDataPlace phone={props.fields?.client_task?.value.contragent.phone}
                                                onSuccess={() => setClientShowState(CLIENT_SHOW_STATES.ADD_ACT)}
                                                onCancel={() => setClientShowState(CLIENT_SHOW_STATES.ADD_ACT)}
                                                meeting_date_field={props.fields.meeting_date}
                                                meeting_place_field={props.fields.meeting_place}
                                                responsible_field={props.fields.responsible}
                                                start_date_field={props.fields.start_date}
                                                client_show_id={client_show_id}
                                                callAlert={callAlert}/>
            case CLIENT_SHOW_STATES.ADD_ACT:
                if (!isCurAdmin && !isCurResponsible) {
                    if (isCurClientShow || isCurClientTasks)
                        return <ClientShowInWorkStatus responsible_field={props.fields.responsible}
                                                       meeting_date_field={props.fields.meeting_date}
                                                       meeting_place_field={props.fields.meeting_place}/>
                    return undefined
                }
                return <ClientShowAddAct meeting_date_field={props.fields.meeting_date}
                                         meeting_place_field={props.fields.meeting_place}
                                         responsible_field={props.fields.responsible}
                                         files_field={props?.fields?.files}
                                         client_show_id={props.fields.id.value}
                                         start_date_field={props.fields.start_date}
                                         onSuccess={() => setClientShowState(CLIENT_SHOW_STATES.CLIENT_SHOW_COMPLETE)}
                                         onCancelClick={() => setClientShowState(CLIENT_SHOW_STATES.INPUT_CANCEL_REASON)}
                                         onNewDateClick={() => setClientShowState(CLIENT_SHOW_STATES.EDIT_DATE_FIELD)}
                                         docData={{
                                             conteragent_fio: props.fields.client_task?.value?.contragent?.fio,
                                             executor_fio: executorName,
                                             conteragent_phone: props.fields.client_task?.value?.contragent?.phone,
                                             landplot_name: props.fields.client_task?.value?.landplot?.microdistrict,
                                             cadastral_number: props.fields.client_task?.value?.landplot?.cadastral,
                                         }}
                                         callAlert={callAlert}/>
            case CLIENT_SHOW_STATES.INPUT_DATA_PLACE:
                if (!isCurAdmin && !isCurResponsible) {
                    if (isCurClientShow || isCurClientTasks)
                        return <ClientShowInProgressStatus/>
                    return undefined
                }
                return <ClientShowInputDataPlace field={props.fields.responsible}
                                                 phone={props.fields?.client_task?.value.contragent.phone}
                                                 onSuccess={() => setClientShowState(CLIENT_SHOW_STATES.ADD_ACT)}
                                                 meeting_date_field={props.fields.meeting_date}
                                                 meeting_place_field={props.fields.meeting_place}
                                                 responsible_field={props.fields.responsible}
                                                 start_date_field={props.fields.start_date}
                                                 client_show_id={client_show_id}
                                                 callAlert={callAlert}/>
            case CLIENT_SHOW_STATES.CHOOSE_RESPONSIBLE:
            default:
                if (!isCurAdmin && !isCurClientShow) {
                    if (isCurClientShow || isCurClientTasks)
                        return <ClientShowInProgressStatus/>
                    return undefined
                }
                return <ClientShowDetailChooseResponsible field={props.fields.responsible}
                                                          onSuccess={() => setClientShowState(CLIENT_SHOW_STATES.INPUT_DATA_PLACE)}
                                                          client_show_id={client_show_id}
                                                          callAlert={callAlert}/>
        }


    }

    return (
        <>
            <div className={"page__info-container"}>
                <LandplotInfo/>
                <p className="_full form__sub-title m-">Данные контрагента</p>
                <ContragentData/>
                <ClientShow/>
            </div>
            {props.fields.comments &&
                <div className="lenta">
                    <CommentComponent comments={props.fields.comments} object_id={props.fields.id.value}
                                      type={props.type} title={'Лента действий'}/>
                </div>
            }
            <HistoryComponent id={props.id} name={'client_show'} />

            <Alert setAlert={setAlert} alert={alert}/>
        </>

    )
}

export default ClientTasksDetail;