import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import HeaderInside from "../../../components/HeaderInside";
import {InfoItem} from "../../../components/InfoItem";
import AddedFormItem from "../../../components/form/AddedFormItem";
import getFormattedDateTime from "../../../functions/getFormattedDateTime";
import Alert from "../../../components/Allert";
import {getCalendarBookingDetail, patchCalendarBooking} from "../api";
import deleteCalendarBooking from "../api/deleteCalendarBooking";

export default function CalendarBookingDetail(props) {
    const params = useParams()
    const userData = useSelector(state => state.user_data.userData)
    const [alert, setAlert] = useState()
    const [fields, setFields] = useState()
    const navigate = useNavigate();

    useEffect(() => {
        const getFields = async () => {
            if (params.id) {
                const data = await getCalendarBookingDetail(params.id)
                const options = (await getCalendarBookingDetail(params.id, true))?.actions?.PUT

                const newFields = {}
                const keys = Object.keys(data || {})
                keys.forEach((key) => {
                    newFields[key] = {...options[key], value: data[key]}
                })
                console.log(newFields)
                setFields(newFields)
            }
        }

        getFields()
    }, [params.id])

    const userHasPermission = (permission) => {
        return true
    }

    const isAdmin = () => {
        const groups = userData.groups ? userData.groups : []
        return groups.indexOf('Admin') !== -1;
    }

    const isAuthor = () => {
        return (+fields?.responsible?.value === +userData.user_id)
    }

    const convertChoices = (choices) => {
        return choices.map((choice) => ({...choice, label: choice.display_name}))
    }

    const convertMemversValue = (value) => {
        return value.map(item => ({value: item}))
    }

    const convertValueToArray = (value) => {
        if (Array.isArray(value)) {
            return value
        }
        return [value]
    }

    const onSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const response = await patchCalendarBooking(params.id, {participants: formData.getAll('participants')})
        if (response) {
            setAlert({type: 'success', title: 'Успешно', text: ''})
        }
    }

    const deleteItem = async () => {
        if (params.id) {
            const response = await deleteCalendarBooking(params.id);
            if (response)
                navigate('/calendar')
        }
    }

    return (
        <div className={"page"}>
            <>
                <HeaderInside title={'Просмотр совещания'}
                              edit={isAdmin() || isAuthor() ? {url: `/calendar-booking/edit/${params.id}`} : false}
                    // create={userHasPermission('add_' + props.model_name) ? {url: `//calendar-booking/create/${params.id}`, title: 'создать'} : false}
                    // delete_btn={userHasPermission('delete_' + props.model_name) ? { deleteItem: deleteItem } : false}
                              delete_btn={isAdmin() || isAuthor() ? {deleteItem: deleteItem} : false}
                              clickEvent={() => navigate('/calendar')}
                />
                <hr/>
                <div className="page__content">
                    {alert && <Alert setAlert={setAlert} alert={alert}/>}
                    <div className={"page__info-container"}>
                        {fields?.title && <InfoItem item={fields.title} classes="_full"/>}
                        {fields?.booking_time && <InfoItem item={fields.booking_time} classes="_full"/>}
                        {fields?.booking_duration && <InfoItem item={fields.booking_duration} classes="_full"/>}
                        {fields?.end_booking_time && <InfoItem item={{
                            ...fields.end_booking_time,
                            value: fields.end_booking_time.value
                        }} classes="_full"/>}
                        {fields?.meeting_room &&
                            <InfoItem
                                item={{
                                    ...fields.meeting_room,
                                    type: 'nested',
                                    choices: convertChoices(fields.meeting_room.choices),
                                    value: convertValueToArray(fields.meeting_room.value)
                                }}
                                classes="_full"/>}
                        {fields?.responsible &&
                            <InfoItem
                                item={{
                                    ...fields.responsible,
                                    type: 'nested',
                                    choices: convertChoices(fields.responsible.choices),
                                    value: convertValueToArray(fields.responsible.value)
                                }}
                                classes="_full"/>}
                        <form className={'_full'} onSubmit={onSubmit}>
                            {fields?.participants &&
                                <AddedFormItem
                                    field={{
                                        ...fields.participants,
                                        value: convertMemversValue(fields.participants.value),
                                        choices: convertChoices(fields.participants.choices),
                                        name: 'participants',
                                    }}
                                    classes="_full"
                                    button={'Добавить участника'}
                                    can_delete={isAuthor() || isAdmin()}
                                    can_add={isAuthor() || isAdmin()}
                                />}
                            {(isAuthor() || isAdmin()) &&
                                <button style={{border: 'none'}} className={'save_button_fixed mt-0 ml-0'}>Сохранить
                                </button>}
                        </form>
                    </div>
                </div>
            </>
        </div>
    )
}