import React, {useEffect, useState} from 'react';

import ViewPage from "../../components/ViewContainer/ViewContainer";
import FormItem from "../../components/form/FormItem";
import FormErrorsContext from "../../functions/formErrorsContext";
import {getActFileRequest, getMicrodistrictOptionsRequest} from "./api";
import Alert from "../../components/Allert";

export default function ClientShowGetActsPage() {
    const [formErrors, setFormErrors] = useState()
    const [microdistrictOptions, setMicrodistrictOptions] = useState()
    const [alert, setAlert] = useState({})

    useEffect(() => {
        const setData = async () => {
            const response = await getMicrodistrictOptionsRequest()
            console.log(response.data.microdistricts.map(item => ({value: item.id, display_name: item.name})))
            setMicrodistrictOptions(response.data.microdistricts.map(item => ({value: item.id, label: item.name})))
        }
        setData()
    }, [])

    const onSubmit = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.currentTarget)
        const date = formData.get('date')
        const microdistrict = formData.get('microdistrict')

        if (date && microdistrict) {
            const [d, m, y] = date.split('.');
            const response = await getActFileRequest(`${y}-${m}-${d}`, microdistrict)

            if (response.ok) {
                const url = URL.createObjectURL(response.data);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'Акт приёма - передачи границ земельного участка.xlsx';
                a.click();
                URL.revokeObjectURL(url);
            } else {
                setAlert({type: 'error', title: 'Ошибка', text: response.data.detail || 'Нет данных для выбранного микрорайона и даты'})
            }
        } else {
            setAlert({type: 'error', title: 'Ошибка', text: 'Заполните все поля'})
        }
    }

    return (
        <ViewPage title={'Акты'} name={'clientShowGetActsPage'}>
            <div className="page__content ">
                <form onSubmit={onSubmit} className="form-container">
                    <FormErrorsContext.Provider value={{formErrors, setFormErrors}}>
                        <FormItem field={{
                            label: "Дата",
                            multiple: false,
                            read_only: false,
                            required: false,
                            type: "date",
                            name: 'date',
                            value: '',
                        }} classes={'_full'}/>
                        <FormItem field={{
                            label: "Микрорайон",
                            multiple: false,
                            read_only: false,
                            required: false,
                            type: "select",
                            children: microdistrictOptions,
                            placeholder: '',
                            name: 'microdistrict',
                            value: '',
                        }} classes={'_full'}/>
                    </FormErrorsContext.Provider>
                    <button type={'submit'} className={'btn _full'}>Скачать файл</button>
                </form>
            </div>
            <Alert setAlert={setAlert} alert={alert}/>
        </ViewPage>
    )
}